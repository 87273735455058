// import Nav from "./Nav.jsx";

// function Header() {
//   return (
//     <header>
//       <Nav />
//     </header>
//   );
// }

// export default Header;

import Nav from "./Nav.jsx";

const Header = ({ userData }) => {
  return (
    <header>
      <Nav userData={userData} />
    </header>
  );
};

export default Header;
