// import React, { useState, useEffect } from "react";

// import ScrollToTop from "../../Components/ScrollToTop/ScrollToTop.jsx";
// import styled, { keyframes } from "styled-components";

// import BestillTimeIcon from "../../assets/images/bestillTimeIcon.png";
// import FornyReseptIcon from "../../assets/images/fornyReseptIcon.png";
// import KontaktLegeIcon from "../../assets/images/kontaktLegeIcon.png";
// import EKonsultasjonIcon from "../../assets/images/eKonsultasjonIcon.png";

// import FurstIcon from "../../assets/images/furstIcon.png";
// import RightIcon from "../../assets/images/rightIcon.png";
// import NyheterCarousel from "../../Components/NyheterCarousel/NyheterCarousel.jsx";
// import Navlinks from "../../Components/Navlinks/Navlinks.jsx";
// import CloseBtn from "../../assets/images/close.png";
// import ViktigCarousel from "../../Components/ViktigCarousel/ViktigCarousel.jsx";

// import Modal from "@mui/material/Modal";
// import Box from "@mui/material/Box";

// // Keyframes for sliding animation

// const slideRight = keyframes`
//   from {
//     opacity: 0;
//     transform: translateX(-30px);
//   }
//   to {
//     opacity: 1;
//     transform: translateY(0);
//   }
// `;

// const slideDown = keyframes`
//   from {
//     opacity: 0;
//     transform: translateY(-30px);
//   }
//   to {
//     opacity: 1;
//     transform: translateY(0);
//   }
// `;

// const slideUp = keyframes`

//   from {
//     opacity: 0;
//     transform: translateY(20px);
//   }
//   to {
//     opacity: 1;
//     transform: translateY(0);
//   }
// `;

// const slideLeft = keyframes`
//   from {
//     opacity: 0;
//     transform: translateX(30px);
//   }
//   to {
//     opacity: 1;
//     transform: translateY(0);
//   }
// `;

// const TitleDiv = styled.div`
//   position: absolute;
//   top: 32%;
//   width: 100%;
//   z-index: 1;
//   opacity: 0;
//   animation: ${({ isLoaded }) => (isLoaded ? slideLeft : "none")} 1.5s ease
//     forwards;
//   animation-delay: 0.2s;

//   @media (max-width: 1149px) {
//     width: 100%;
//     right: 0;
//   }
// `;

// const StyledH1 = styled.h1`
//   text-align: center;
//   font-size: 2.6rem;
//   font-family: "NunitoSansLight", sans-serif;
//   text-shadow: 0px 0.75px 1px rgba(0, 0, 0, 0.175);
//   margin: 0.5rem 0;
//   font-family: "Manrope";
//   font-weight: 500;
//   letter-spacing: 4px;

//   @media (max-width: 649px) {
//     font-size: 1.8rem;
//   }
// `;

// const BannerDiv = styled.div`
//   position: relative;
//   width: 100%;
//   height: 300px;
//   max-height: 300px;
//   overflow: hidden;

//   opacity: 0;
//   animation: ${({ isLoaded }) => (isLoaded ? slideUp : "none")} 1s ease forwards;
//   box-shadow: 0 1.2px 3px 0.2px rgba(0, 0, 0, 0.3);

//   @media (max-width: 649px) {
//     height: 40vh;
//   }

//   @media (min-width: 1600px) {
//     margin: 0 auto;
//     max-width: 1600px;
//     border-radius: 7px;
//   }
// `;

// const StyledImg = styled.img`
//   width: 100%;
//   height: 100%;
//   object-fit: cover;

//   // @media (min-width: 1300px) {
//   //   border-radius: 10px;
//   // }
// `;

// const StyledOverlay = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(255, 255, 255, 0.7);

//   // @media (min-width: 1300px) {
//   //   border-radius: 10px;
//   // }
// `;

// const ButtonDiv = styled.div`
//   width: 97%;
//   display: flex;
//   // justify-content: space-between;
//   justify-content: center;
//   align-items: center;
//   margin: 5vh auto;
//   flex-wrap: wrap;
//   gap: 2.5rem;
//   margin: 2.5rem auto;

//   @media (min-width: 1000px) {
//     width: 90%;
//   }

//   @media (max-width: 500px) {
//     margin: 2rem auto;
//   }

//   /* Targeting the children */
//   > * {
//     animation-delay: 0.2s;
//   }

//   > *:nth-child(1) {
//     animation-delay: 0.2s;
//   }

//   > *:nth-child(2) {
//     animation-delay: 0.4s;
//   }

//   > *:nth-child(3) {
//     animation-delay: 0.6s;
//   }

//   > *:nth-child(4) {
//     animation-delay: 0.8s;
//   }

//   > *:nth-child(5) {
//     animation-delay: 1s;
//   }
// `;

// const StyledButton = styled.div`
//   width: 300px;
//   height: 75px;
//   border-radius: 500px;
//   // background-color: #fffbe5;
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   transition: transform 0.3s ease, box-shadow 0.3s ease;
//   box-shadow: 0 1.2px 3px 0 rgba(0, 0, 0, 0.3);

//   opacity: 0;
//   animation: ${({ isLoaded }) => (isLoaded ? slideDown : "none")} 1s ease
//     forwards;

//   &:hover {
//     transform: translateY(-3px);
//     box-shadow: 0 3.5px 9px rgba(0, 0, 0, 0.1);
//   }
// `;

// const ButtonIcon = styled.img`
//   margin: 0 0 0 25px;
//   height: 60%;
//   object-fit: contain;
// `;

// const ButtonText = styled.h2`
//   font-family: "NunitoSansMedium", sans-serif;
//   font-size: 1.3rem;
//   font-weight: 600;
//   width: 75%;
//   text-align: center;
// `;

// // const modalStyle = {
// //   position: "absolute",
// //   top: "50%",
// //   left: "50%",
// //   transform: "translate(-50%, -50%)",
// //   width: "70%",
// //   maxWidth: 1000,
// //   borderRadius: 3,
// //   height: "auto",
// //   maxHeight: "80%",
// //   overflow: "auto",
// //   bgcolor: "background.paper",
// //   boxShadow: 24,
// //   p: 4,
// // };

// const modalStyle = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "90%",
//   maxWidth: 800,
//   borderRadius: 3,
//   height: "auto",
//   maxHeight: "80%",
//   display: "flex",
//   flexDirection: "column",
//   bgcolor: "background.paper",
//   boxShadow: 24,
//   p: 4,
//   paddingRight: 0,
// };

// const StyledCloseBtn = styled.img`
//   position: absolute;
//   top: 1.2rem;
//   right: 1.2rem;
//   width: 20px;
//   padding: 10px;
//   cursor: pointer;
// `;

// const StyledLink = styled.a`
//   font-size: 1.2rem;
//   color: #313035;
//   text-decoration: none;
//   font-family: "NunitoSansRegular", sans-serif;
//   font-weight: 600;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   border-radius: 12px;
//   padding: 12px 25px;

//   box-shadow: 0 1.2px 1.5px 0 rgba(0, 0, 0, 0.2);
//   transition: all 0.3s ease;
//   margin: 2rem 2rem 0 0;
//   background-color: #e5f5ff;
//   letter-spacing: 2px;

//   &:hover {
//     background: ${(props) =>
//       props.hoverColor || "#fffbe5"}; /* Dynamically change hover color */
//   }
// `;

// function Home() {
//   const [openModal, setOpenModal] = useState(false);
//   const [selectedButton, setSelectedButton] = useState(null);
//   const [isLoaded, setIsLoaded] = useState(false);
//   const [isNotLoaded, setIsNotLoaded] = useState(false);

//   const [userData, setUserData] = useState(null);
//   const [showLogo, setShowLogo] = useState(false);

//   ScrollToTop();

//   let hoverColor;

//   switch (selectedButton?.title) {
//     case "Timebestilling":
//       hoverColor = "#F3FDFA";
//       break;
//     case "Forny resepter":
//       hoverColor = "#FEF4F4";
//       break;
//     case "Kontakt kontoret":
//       hoverColor = " #FEFAF4";
//       break;
//     case "E-konsultasjon":
//       hoverColor = " #FBF3FD";
//       break;
//     case "Fürst Pasient":
//       hoverColor = "#F4F7FE";
//       break;
//     default:
//       hoverColor = "#fffbe5";
//   }

//   useEffect(() => {
//     window.addEventListener("resize", () => {
//       if (window.innerWidth >= 1099) {
//         setShowLogo(false);
//       } else {
//         setShowLogo(true);
//       }
//     });
//   }, []);

//   // useEffect(() => {
//   //   // const fetchUserData = sessionStorage.getItem("userData");
//   //   const fetchUserData = null;
//   //   let parsedUserData = null;
//   //   if (fetchUserData) {
//   //     parsedUserData = JSON.parse(fetchUserData);
//   //   }

//   //   if (
//   //     fetchUserData &&
//   //     parsedUserData &&
//   //     parsedUserData?.acf &&
//   //     parsedUserData?.acf?.clinicdata
//   //   ) {
//   //     setUserData(JSON.parse(parsedUserData.acf.clinicdata));

//   //     setIsLoaded(true);
//   //     if (window.innerWidth >= 1099) {
//   //       setShowLogo(false);
//   //     } else {
//   //       setShowLogo(true);
//   //     }
//   //   } else {
//   //     return (
//   //       <div
//   //         style={{
//   //           width: "100vw",
//   //           height: "50vh",
//   //           textAlign: "center",
//   //           display: "flex",
//   //           alignItems: "center",
//   //           justifyContent: "center",
//   //           fontSize: "2rem",
//   //           fontFamily: "NunitoSansRegular, Arial",
//   //         }}
//   //       >
//   //         Det skjedde en feil, vennligst prøv igjen!
//   //       </div>
//   //     );
//   //   }
//   // }, []);

//   // const buttons = [
//   //   {
//   //     title: "Timebestilling",
//   //     subTitle: "Vi anbefaler å bestille time digitalt gjennom Helsenorge",
//   //     content:
//   //       "Velg time selv: Vi tilbyr et antall timer som du kan velge selv direkte fra legens timebok. Du kan også avbestille timer her.",
//   //     linkText: "Logg inn på HelseNorge",
//   //     link: "https://tjenester.helsenorge.no/timeavtaler/bestill-time-velger",
//   //   },
//   //   {
//   //     title: "Forny resepter",
//   //     subTitle: "Vi anbefaler å bestille resept digitalt gjennom Helsenorge!",
//   //     content:
//   //       "Du kan sende en melding til fastlegen din og be om å få fornyet resept på dine faste legemidler, medisinsk utstyr og annet som forskrives på resept.",
//   //     subContent: "A- og B-preparater kan IKKE fornyes her.",
//   //     linkText: "Logg inn på HelseNorge",
//   //     link: "https://tjenester.helsenorge.no/skjemautfyller/NHN_Sjekkpunkt_Resept",
//   //     info: "Vi fornyer normalt resepter i løpet av 5 virkedager, av og til ønsker legen at du kommer til time for fornyelse, dette gjelder f.eks. A- og B-preparater. Dette vil du få beskjed om. Gi beskjed om reseptene haster.",
//   //   },
//   //   {
//   //     title: "Kontakt legekontoret",
//   //     subTitle:
//   //       "Tjenesten ''kontakt legekontoret'' gir deg en trygg kommunikasjonskanal med legekontoret.",
//   //     content:
//   //       "Tjenesten ''kontakt legekontoret'' gir deg en trygg kommunikasjonskanal med legekontoret. Denne tjenesten kan brukes til administrative spørsmål, som ferieavvikling og adkomst.",
//   //     subContent:
//   //       "Spørsmålene via denne tjenesten vil normalt besvares av resepsjonen på legekontoret.​ Du kan ikke få helsehjelp via denne tjeneste",
//   //     linkText: "Logg inn på HelseNorge",
//   //     link: "https://tjenester.helsenorge.no/Fastlegen",
//   //   },
//   //   {
//   //     title: "E-konsultasjon",
//   //     subTitle: "E-konsultasjon er en nettbasert skriftlig konsultasjon.",
//   //     content:
//   //       "E-konsultasjon er en nettbasert skriftlig konsultasjon. Du betaler egenandel og fakturagebyr som ved vanlig legetime. I dag gjelder denne tjenesten bare for kjente tilstander, og bare når det ikke krever fysisk oppmøte.",
//   //     subContent:
//   //       "NB! Det kan ta inntil en uke før noen leser meldingen din! Tjenesten skal ikke benyttes til øyeblikkelig hjelp og akuttsituasjoner! Hvis du har nye plager eller trenger sykemelding, må du også bestille en vanlig legetime.",
//   //     linkText: "Logg inn på HelseNorge",
//   //     link: "https://tjenester.helsenorge.no/meldinger/skriv-melding",
//   //   },
//   //   {
//   //     title: "Fürst",
//   //     subTitle: "Fürst er Norges største private laboratorium.",
//   //     content:
//   //       "Fürst er Norges største private laboratorium. Fürst tilbyr et bredt spekter av analyser, prøvetaking og laboratorietjenester.",
//   //     subContent:
//   //       "Logg inn på Fürst Pasient for å se prøvesvar eller bestille prøver.",
//   //     linkText: "Logg inn på Fürst Pasient",
//   //     link: "https://www.furstpasient.no/",
//   //   },
//   // ];

//   useEffect(() => {
//     const fetchUserData = sessionStorage.getItem("userData");
//     let parsedUserData = null;

//     if (fetchUserData) {
//       parsedUserData = JSON.parse(fetchUserData);
//     }

//     if (
//       fetchUserData &&
//       parsedUserData &&
//       parsedUserData?.acf &&
//       parsedUserData?.acf?.clinicdata
//     ) {
//       setUserData(JSON.parse(parsedUserData.acf.clinicdata));
//       setIsLoaded(true);

//       if (window.innerWidth >= 1099) {
//         setShowLogo(false);
//       } else {
//         setShowLogo(true);
//       }
//     } else {
//       setIsNotLoaded(true);
//     }
//   }, []);

//   const featureMappings = {
//     bestilleTime: "Timebestilling",
//     fornyResept: "Forny resepter",
//     kontaktLege: "Kontakt kontoret",
//     EKonsultasjon: "E-konsultasjon",
//     furst: "Fürst Pasient",
//   };

//   // Function to retrieve links dynamically
//   const getLinkForFeature = (key) => {
//     const links = {
//       bestilleTime:
//         "https://tjenester.helsenorge.no/timeavtaler/bestill-time-velger",
//       fornyResept:
//         "https://tjenester.helsenorge.no/skjemautfyller/NHN_Sjekkpunkt_Resept",
//       kontaktLege: "https://tjenester.helsenorge.no/Fastlegen",
//       EKonsultasjon: "https://tjenester.helsenorge.no/meldinger/skriv-melding",
//       furst: "https://www.furstpasient.no/",
//     };
//     return links[key] || "#"; // Fallback to "#" if no link exists
//   };

//   let buttons = [];
//   if (userData && userData.selectedFeaturesText.length > 0) {
//     // console.log("Selected Features Text:", userData.selectedFeaturesText);
//     buttons = userData.selectedFeaturesText.map((feature) => ({
//       title: featureMappings[feature.key] || "Ukjent knapp", // Fallback if key is missing
//       subTitle: feature.title || "", // Use user input or fallback
//       content: feature.description || "", // Use user input or fallback
//       linkText: "Logg inn på HelseNorge",
//       link: getLinkForFeature(feature.key),
//     }));
//   }

//   return (
//     <>
//       {isLoaded && userData && (
//         <>
//           <BannerDiv isLoaded={isLoaded}>
//             {userData.doctorsOffice.logoImage && showLogo && (
//               <img
//                 tabIndex="0"
//                 src={userData.doctorsOffice.logoImage}
//                 alt="Logo"
//                 style={{
//                   position: "absolute",
//                   top: 20,
//                   left: 20,

//                   // height: "auto",
//                   width: 70,
//                   maxHeight: 30,
//                   objectFit: "contain",
//                   zIndex: 1,
//                 }}
//               />
//             )}
//             {userData.doctorsOffice.officeName && (
//               <TitleDiv isLoaded={isLoaded}>
//                 {/* <StyledH1
//                   style={{
//                     fontWeight: 500,
//                     marginBottom: "1rem",
//                   }}
//                 >
//                   Velkommen til
//                 </StyledH1> */}
//                 {/* <div
//                   style={{
//                     display: "flex",
//                     alignItems: "center",
//                     gap: 10,
//                     margin: "0 auto",
//                     justifyContent: "center",
//                   }}
//                 >
//                   {userData.doctorsOffice.logoImage && (
//                     <img
//                       src={userData.doctorsOffice.logoImage}
//                       alt="Logo bilde"
//                       style={{
//                         width: "25px",
//                         height: "25px",
//                         objectFit: "cover",
//                       }}
//                     />
//                   )} */}

//                 <StyledH1 tabIndex="0">
//                   {userData.doctorsOffice.officeName}
//                 </StyledH1>
//                 {/* </div> */}
//               </TitleDiv>
//             )}
//             {userData.doctorsOffice.bannerImage && (
//               <>
//                 <StyledOverlay />
//                 <StyledImg
//                   tabIndex="0"
//                   src={userData.doctorsOffice.bannerImage}
//                   alt="Banner bilde"
//                 />
//               </>
//             )}
//           </BannerDiv>

//           {userData.importantInfo.length > 0 && (
//             <div
//               style={{
//                 background: "linear-gradient(180deg, #f4f7fe80, #f4f7fe01)",
//               }}
//             >
//               <ViktigCarousel news={userData.importantInfo} />
//             </div>
//           )}

//           {(userData.selectedFeatures.bestilleTime ||
//             userData.selectedFeatures.fornyResept ||
//             userData.selectedFeatures.kontaktLege ||
//             userData.selectedFeatures.EKonsultasjon ||
//             userData.selectedFeatures.furst) && (
//             <ButtonDiv
//               style={{
//                 maxWidth:
//                   userData.selectedFeatures.bestilleTime &&
//                   userData.selectedFeatures.fornyResept &&
//                   userData.selectedFeatures.kontaktLege &&
//                   userData.selectedFeatures.EKonsultasjon &&
//                   userData.selectedFeatures.furst
//                     ? "80%"
//                     : "97%",
//               }}
//             >
//               {userData.selectedFeatures.bestilleTime && (
//                 <StyledButton
//                   role="button"
//                   tabIndex="0"
//                   aria-label="Bestill time knapp"
//                   style={{ backgroundColor: "#F3FDFA" }}
//                   isLoaded={isLoaded}
//                   onClick={() => {
//                     setSelectedButton(buttons[0]);
//                     setOpenModal(true);
//                   }}
//                   onKeyDown={(e) => {
//                     if (e.key === "Enter" || e.keyCode === 13) {
//                       e.preventDefault(); // Prevent form submission on Enter key
//                       setSelectedButton(buttons[0]);
//                       setOpenModal(true);
//                     }
//                   }}
//                 >
//                   <ButtonIcon src={BestillTimeIcon} alt="Bestill time ikon" />
//                   <ButtonText>Bestill time</ButtonText>
//                 </StyledButton>
//               )}

//               {userData.selectedFeatures.fornyResept && (
//                 <StyledButton
//                   role="button"
//                   tabIndex="0"
//                   aria-label="Forny resept knapp"
//                   isLoaded={isLoaded}
//                   // style={{ backgroundColor: "#e5f5ff" }}
//                   style={{ backgroundColor: "#FEF4F4" }}
//                   onClick={() => {
//                     setSelectedButton(buttons[1]);
//                     setOpenModal(true);
//                   }}
//                   onKeyDown={(e) => {
//                     if (e.key === "Enter" || e.keyCode === 13) {
//                       e.preventDefault(); // Prevent form submission on Enter key
//                       setSelectedButton(buttons[1]);
//                       setOpenModal(true);
//                     }
//                   }}
//                 >
//                   <ButtonIcon src={FornyReseptIcon} alt="Forny resept ikon" />
//                   <ButtonText>Forny resept</ButtonText>
//                 </StyledButton>
//               )}

//               {userData.selectedFeatures.kontaktLege && (
//                 <StyledButton
//                   role="button"
//                   tabIndex="0"
//                   aria-label="Kontakt legekontoret knapp"
//                   isLoaded={isLoaded}
//                   // style={{ backgroundColor: "#e5fff0" }}
//                   style={{ backgroundColor: "#FEFAF4" }}
//                   onClick={() => {
//                     setSelectedButton(buttons[2]);
//                     setOpenModal(true);
//                   }}
//                   onKeyDown={(e) => {
//                     if (e.key === "Enter" || e.keyCode === 13) {
//                       e.preventDefault(); // Prevent form submission on Enter key
//                       setSelectedButton(buttons[2]);
//                       setOpenModal(true);
//                     }
//                   }}
//                 >
//                   <ButtonIcon src={KontaktLegeIcon} alt="Kontakt lege ikon" />
//                   <ButtonText>Kontakt kontoret</ButtonText>
//                 </StyledButton>
//               )}

//               {userData.selectedFeatures.EKonsultasjon && (
//                 <StyledButton
//                   role="button"
//                   tabIndex="0"
//                   aria-label="E-konsultasjon knapp"
//                   isLoaded={isLoaded}
//                   // style={{ backgroundColor: "#EEEBFF" }}
//                   style={{ backgroundColor: "#FBF3FD" }}
//                   onClick={() => {
//                     setSelectedButton(buttons[3]);
//                     setOpenModal(true);
//                   }}
//                   onKeyDown={(e) => {
//                     if (e.key === "Enter" || e.keyCode === 13) {
//                       e.preventDefault(); // Prevent form submission on Enter key
//                       setSelectedButton(buttons[3]);
//                       setOpenModal(true);
//                     }
//                   }}
//                 >
//                   <ButtonIcon
//                     src={EKonsultasjonIcon}
//                     alt="E-konsultasjon ikon"
//                   />
//                   <ButtonText>E-konsultasjon</ButtonText>
//                 </StyledButton>
//               )}

//               {userData.selectedFeatures.furst && (
//                 <StyledButton
//                   role="button"
//                   tabIndex="0"
//                   aria-label="Furst pasient knapp"
//                   isLoaded={isLoaded}
//                   // style={{ backgroundColor: "#FFE5E5" }}
//                   style={{ backgroundColor: "#F4F7FE" }}
//                   onClick={() => {
//                     setSelectedButton(buttons[4]);
//                     setOpenModal(true);
//                   }}
//                   onKeyDown={(e) => {
//                     if (e.key === "Enter" || e.keyCode === 13) {
//                       e.preventDefault(); // Prevent form submission on Enter key
//                       setSelectedButton(buttons[4]);
//                       setOpenModal(true);
//                     }
//                   }}
//                 >
//                   <ButtonIcon src={FurstIcon} alt="Furst ikon" />
//                   <ButtonText>Fürst Pasient</ButtonText>
//                 </StyledButton>
//               )}
//             </ButtonDiv>
//           )}
//           {userData.importantInfo.length > 0 && (
//             <>
//               <div
//                 style={{
//                   // borderTop: "2px solid #e5e5e5",
//                   width: "80%",
//                   margin: "4rem auto 3rem",
//                 }}
//               />
//               <NyheterCarousel news={userData.importantInfo} />
//             </>
//           )}

//           {/* <Navlinks /> */}

//           <Modal
//             open={openModal}
//             onClose={() => setOpenModal(false)}
//             aria-labelledby="modal-title"
//             aria-describedby="modal-description"
//           >
//             {/* <Box sx={modalStyle}>
//               {selectedButton && (
//                 <>
//                   <div>
//                     <StyledCloseBtn
//                       src={CloseBtn}
//                       alt="Close button"
//                       className="close-btn"
//                       onClick={() => setOpenModal(false)}
//                     />
//                     {selectedButton.title && (
//                       <h2
//                         style={{
//                           marginTop: 0,
//                           fontFamily: "NunitoSansRegular",
//                           letterSpacing: "2px",
//                           maxWidth: "85%",
//                         }}
//                       >
//                         {selectedButton.title}
//                       </h2>
//                     )}
//                     {selectedButton.subTitle && (
//                       <p
//                         style={{
//                           fontSize: "1.2rem",
//                           fontFamily: "NunitoSansRegular",
//                           fontWeight: 600,
//                           letterSpacing: "1.5px",
//                         }}
//                       >
//                         {selectedButton.subTitle}
//                       </p>
//                     )}
//                     {selectedButton.content && (
//                       <pre
//                         style={{
//                           fontSize: "1.2rem",
//                           fontFamily: "NunitoSansRegular",
//                           letterSpacing: "1px",
//                           whiteSpace: "pre-wrap",
//                         }}
//                       >
//                         {selectedButton.content}
//                       </pre>
//                     )}
//                     {selectedButton.subContent && (
//                       <p
//                         style={{
//                           fontSize: "1.2rem",
//                           fontFamily: "NunitoSansRegular",
//                           letterSpacing: "1px",
//                         }}
//                       >
//                         {selectedButton.subContent}
//                       </p>
//                     )}
//                     {selectedButton.info && (
//                       <p
//                         style={{
//                           fontSize: "1.2rem",
//                           fontFamily: "NunitoSansRegular",
//                           letterSpacing: "1px",
//                         }}
//                       >
//                         {selectedButton.info}
//                       </p>
//                     )}
//                   </div>
//                   {selectedButton.link && (
//                     <StyledLink href={selectedButton.link} target="_blank">
//                       {selectedButton.linkText}
//                       <img
//                         src={RightIcon}
//                         alt="External link"
//                         style={{
//                           width: "30px",
//                           height: "30px",
//                           objectFit: "contain",
//                         }}
//                       />
//                     </StyledLink>
//                   )}
//                 </>
//               )}
//             </Box> */}

//             <Box sx={modalStyle}>
//               <div
//                 style={{
//                   flex: 1,
//                   overflowY: "auto",
//                   padding: "1rem 3rem 1rem 1rem",
//                 }}
//               >
//                 {selectedButton && (
//                   <>
//                     <StyledCloseBtn
//                       role="button"
//                       tabIndex="0"
//                       aria-label="Lukkeknapp"
//                       src={CloseBtn}
//                       alt="Close button"
//                       className="close-btn"
//                       onClick={() => setOpenModal(false)}
//                       onKeyDown={(e) => {
//                         if (e.key === "Enter" || e.keyCode === 13) {
//                           e.preventDefault(); // Prevent form submission on Enter key
//                           setOpenModal(false);
//                         }
//                       }}
//                     />
//                     {selectedButton.title && (
//                       <h2
//                         tabIndex="0"
//                         style={{
//                           marginTop: 0,
//                           fontFamily: "NunitoSansRegular",
//                           letterSpacing: "2px",
//                           maxWidth: "85%",
//                         }}
//                       >
//                         {selectedButton.title}
//                       </h2>
//                     )}
//                     {selectedButton.subTitle && (
//                       <p
//                         tabIndex="0"
//                         style={{
//                           fontSize: "1.2rem",
//                           fontFamily: "NunitoSansRegular",
//                           fontWeight: 600,
//                           letterSpacing: "1.5px",
//                         }}
//                       >
//                         {selectedButton.subTitle}
//                       </p>
//                     )}
//                     {selectedButton.content && (
//                       <pre
//                         tabIndex="0"
//                         style={{
//                           fontSize: "1.2rem",
//                           fontFamily: "NunitoSansRegular",
//                           letterSpacing: "1px",
//                           whiteSpace: "pre-wrap",
//                         }}
//                       >
//                         {selectedButton.content}
//                       </pre>
//                     )}
//                     {selectedButton.subContent && (
//                       <p
//                         tabIndex="0"
//                         style={{
//                           fontSize: "1.2rem",
//                           fontFamily: "NunitoSansRegular",
//                           letterSpacing: "1px",
//                         }}
//                       >
//                         {selectedButton.subContent}
//                       </p>
//                     )}
//                     {selectedButton.info && (
//                       <p
//                         tabIndex="0"
//                         style={{
//                           fontSize: "1.2rem",
//                           fontFamily: "NunitoSansRegular",
//                           letterSpacing: "1px",
//                         }}
//                       >
//                         {selectedButton.info}
//                       </p>
//                     )}
//                   </>
//                 )}
//               </div>

//               {selectedButton?.link && (
//                 <StyledLink
//                   role="button"
//                   tabIndex="0"
//                   aria-label={`Link til innlogging hos Helsenorge`}
//                   href={selectedButton.link}
//                   target="_blank"
//                   hoverColor={hoverColor}
//                 >
//                   {selectedButton.linkText}
//                   <img
//                     src={RightIcon}
//                     alt="External link"
//                     style={{
//                       width: "30px",
//                       height: "30px",
//                       objectFit: "contain",
//                     }}
//                   />
//                 </StyledLink>
//               )}
//             </Box>
//           </Modal>
//         </>
//       )}
//       {!isLoaded && isNotLoaded && (
//         <div
//           style={{
//             width: "100%",
//             height: "50vh",
//             textAlign: "center",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             fontSize: "2rem",
//             fontFamily: "NunitoSansRegular, Arial",
//           }}
//         >
//           Det skjedde en feil, vennligst prøv igjen!
//         </div>
//       )}
//     </>
//   );
// }

// export default Home;

import React, { useState, useEffect } from "react";

import ScrollToTop from "../../Components/ScrollToTop/ScrollToTop.jsx";
import styled, { keyframes } from "styled-components";

import BestillTimeIcon from "../../assets/images/bestillTimeIcon.png";
import FornyReseptIcon from "../../assets/images/fornyReseptIcon.png";
import KontaktLegeIcon from "../../assets/images/kontaktLegeIcon.png";
import EKonsultasjonIcon from "../../assets/images/eKonsultasjonIcon.png";

import FurstIcon from "../../assets/images/furstIcon.png";
import RightIcon from "../../assets/images/rightIcon.png";
import NyheterCarousel from "../../Components/NyheterCarousel/NyheterCarousel.jsx";
import CloseBtn from "../../assets/images/close.png";
import ViktigCarousel from "../../Components/ViktigCarousel/ViktigCarousel.jsx";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

// Keyframes for sliding animation

const slideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideUp = keyframes`

  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const TitleDiv = styled.div`
  position: absolute;
  top: 32%;
  width: 100%;
  z-index: 1;
  opacity: 0;
  animation: ${({ isLoaded }) => (isLoaded ? slideLeft : "none")} 1.5s ease
    forwards;
  animation-delay: 0.2s;

  @media (max-width: 1149px) {
    width: 100%;
    right: 0;
  }
`;

const StyledH1 = styled.h1`
  text-align: center;
  font-size: 2.6rem;
  font-family: "NunitoSansLight", sans-serif;
  text-shadow: 0px 0.75px 1px rgba(0, 0, 0, 0.175);
  margin: 0.5rem 0;
  font-family: "Manrope";
  font-weight: 500;
  letter-spacing: 4px;

  @media (max-width: 649px) {
    font-size: 1.8rem;
  }
`;

const BannerDiv = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  max-height: 300px;
  overflow: hidden;

  opacity: 0;
  animation: ${({ isLoaded }) => (isLoaded ? slideUp : "none")} 1s ease forwards;
  box-shadow: 0 1.2px 3px 0.2px rgba(0, 0, 0, 0.3);

  @media (max-width: 649px) {
    height: 40vh;
  }

  @media (min-width: 1600px) {
    margin: 0 auto;
    max-width: 1600px;
    border-radius: 7px;
  }
`;

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  // @media (min-width: 1300px) {
  //   border-radius: 10px;
  // }
`;

const StyledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);

  // @media (min-width: 1300px) {
  //   border-radius: 10px;
  // }
`;

const ButtonDiv = styled.div`
  width: 100%;
  display: flex;
  // justify-content: space-between;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  // gap: 2.5rem;
  row-gap: 2.5rem; /* Vertical gap */
  column-gap: 5rem; /* Horizontal gap */
  margin: 2.5rem auto;

  @media (min-width: 1000px) {
    width: 90%;
  }

  @media (max-width: 500px) {
    margin: 2rem auto;
  }

  /* Targeting the children */
  > * {
    animation-delay: 0.2s;
  }

  > *:nth-child(1) {
    animation-delay: 0.2s;
  }

  > *:nth-child(2) {
    animation-delay: 0.4s;
  }

  > *:nth-child(3) {
    animation-delay: 0.6s;
  }

  > *:nth-child(4) {
    animation-delay: 0.8s;
  }

  > *:nth-child(5) {
    animation-delay: 1s;
  }
`;

const StyledButton = styled.div`
  width: 300px;
  height: 75px;
  border-radius: 500px;
  // background-color: #fffbe5;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 1.2px 3px 0 rgba(0, 0, 0, 0.3);

  opacity: 0;
  animation: ${({ isLoaded }) => (isLoaded ? slideDown : "none")} 1s ease
    forwards;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 3.5px 9px rgba(0, 0, 0, 0.1);
  }
`;

const ButtonIcon = styled.img`
  margin: 0 0 0 25px;
  height: 60%;
  object-fit: contain;
`;

const ButtonText = styled.h2`
  font-family: "NunitoSansMedium", sans-serif;
  font-size: 1.3rem;
  font-weight: 600;
  width: 75%;
  text-align: center;
`;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 800,
  borderRadius: 3,
  height: "auto",
  maxHeight: "80%",
  display: "flex",
  flexDirection: "column",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  paddingRight: 0,
};

const StyledCloseBtn = styled.img`
  position: absolute;
  top: 1.2rem;
  right: 1.2rem;
  width: 20px;
  padding: 10px;
  cursor: pointer;
`;

const StyledLink = styled.a`
  font-size: 1.2rem;
  color: #313035;
  text-decoration: none;
  font-family: "NunitoSansRegular", sans-serif;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  padding: 12px 25px;

  box-shadow: 0 1.2px 1.5px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  margin: 2rem 2rem 0 0;
  background-color: #e5f5ff;
  letter-spacing: 2px;

  &:hover {
    background: ${(props) =>
      props.hoverColor || "#fffbe5"}; /* Dynamically change hover color */
  }
`;

const Home = ({ userData }) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedButton, setSelectedButton] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  // const [userData, setUserData] = useState(null);
  const [showLogo, setShowLogo] = useState(false);

  ScrollToTop();

  let hoverColor;

  switch (selectedButton?.title) {
    case "Timebestilling":
      hoverColor = "#F3FDFA";
      break;
    case "Forny resepter":
      hoverColor = "#FEF4F4";
      break;
    case "Kontakt kontoret":
      hoverColor = " #FEFAF4";
      break;
    case "E-konsultasjon":
      hoverColor = " #FBF3FD";
      break;
    case "Fürst Pasient":
      hoverColor = "#F4F7FE";
      break;
    default:
      hoverColor = "#fffbe5";
  }

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth >= 1099) {
        setShowLogo(false);
      } else {
        setShowLogo(true);
      }
    });
  }, []);

  useEffect(() => {
    setIsLoaded(true);
    if (window.innerWidth >= 1099) {
      setShowLogo(false);
    } else {
      setShowLogo(true);
    }
  }, []);

  const featureMappings = {
    bestilleTime: "Timebestilling",
    fornyResept: "Forny resepter",
    kontaktLege: "Kontakt kontoret",
    EKonsultasjon: "E-konsultasjon",
    furst: "Fürst Pasient",
  };

  // Function to retrieve links dynamically
  const getLinkForFeature = (key) => {
    const links = {
      bestilleTime:
        "https://tjenester.helsenorge.no/timeavtaler/bestill-time-velger",
      fornyResept:
        "https://tjenester.helsenorge.no/skjemautfyller/NHN_Sjekkpunkt_Resept",
      kontaktLege: "https://tjenester.helsenorge.no/Fastlegen",
      EKonsultasjon: "https://tjenester.helsenorge.no/meldinger/skriv-melding",
      furst: "https://www.furstpasient.no/",
    };
    return links[key] || "#"; // Fallback to "#" if no link exists
  };

  let buttons = [];
  if (userData && userData.selectedFeaturesText.length > 0) {
    buttons = userData.selectedFeaturesText.map((feature) => ({
      title: featureMappings[feature.key] || "Ukjent knapp", // Fallback if key is missing
      subTitle: feature.title || "", // Use user input or fallback
      content: feature.description || "", // Use user input or fallback
      linkText: "Logg inn på HelseNorge",
      link: getLinkForFeature(feature.key),
    }));
  }

  return (
    <>
      {userData ? (
        <>
          <BannerDiv isLoaded={isLoaded}>
            {userData.doctorsOffice.logoImage && showLogo && (
              <img
                tabIndex="0"
                src={userData.doctorsOffice.logoImage}
                alt="Logo"
                style={{
                  position: "absolute",
                  top: 20,
                  left: 20,

                  // height: "auto",
                  maxWidth: 70,
                  maxHeight: 30,
                  objectFit: "contain",
                  zIndex: 1,
                }}
              />
            )}
            {userData.doctorsOffice.officeName && (
              <TitleDiv isLoaded={isLoaded}>
                {/* <StyledH1
                  style={{
                    fontWeight: 500,
                    marginBottom: "1rem",
                  }}
                >
                  Velkommen til
                </StyledH1> */}
                {/* <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                    margin: "0 auto",
                    justifyContent: "center",
                  }}
                >
                  {userData.doctorsOffice.logoImage && (
                    <img
                      src={userData.doctorsOffice.logoImage}
                      alt="Logo bilde"
                      style={{
                        width: "25px",
                        height: "25px",
                        objectFit: "cover",
                      }}
                    />
                  )} */}

                <StyledH1 tabIndex="0">
                  {userData.doctorsOffice.officeName}
                </StyledH1>
                {/* </div> */}
              </TitleDiv>
            )}
            {userData.doctorsOffice.bannerImage && (
              <>
                <StyledOverlay />
                <StyledImg
                  tabIndex="0"
                  src={userData.doctorsOffice.bannerImage}
                  alt="Banner bilde"
                />
              </>
            )}
          </BannerDiv>

          {userData.importantInfo.length > 0 && (
            <div
              style={{
                background: "linear-gradient(180deg, #f4f7fe80, #f4f7fe01)",
              }}
            >
              <ViktigCarousel news={userData.importantInfo} />
            </div>
          )}

          {(userData.selectedFeatures.bestilleTime ||
            userData.selectedFeatures.fornyResept ||
            userData.selectedFeatures.kontaktLege ||
            userData.selectedFeatures.EKonsultasjon ||
            userData.selectedFeatures.furst) && (
            <ButtonDiv
              style={{
                maxWidth:
                  userData.selectedFeatures.bestilleTime &&
                  userData.selectedFeatures.fornyResept &&
                  userData.selectedFeatures.kontaktLege &&
                  userData.selectedFeatures.EKonsultasjon &&
                  userData.selectedFeatures.furst
                    ? "88%"
                    : "97%",
              }}
            >
              {userData.selectedFeatures.bestilleTime && (
                <StyledButton
                  role="button"
                  tabIndex="0"
                  aria-label="Bestill time knapp"
                  style={{ backgroundColor: "#F3FDFA" }}
                  isLoaded={isLoaded}
                  onClick={() => {
                    setSelectedButton(buttons[0]);
                    setOpenModal(true);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.keyCode === 13) {
                      e.preventDefault(); // Prevent form submission on Enter key
                      setSelectedButton(buttons[0]);
                      setOpenModal(true);
                    }
                  }}
                >
                  <ButtonIcon src={BestillTimeIcon} alt="Bestill time ikon" />
                  <ButtonText>Bestill time</ButtonText>
                </StyledButton>
              )}

              {userData.selectedFeatures.fornyResept && (
                <StyledButton
                  role="button"
                  tabIndex="0"
                  aria-label="Forny resept knapp"
                  isLoaded={isLoaded}
                  // style={{ backgroundColor: "#e5f5ff" }}
                  style={{ backgroundColor: "#FEF4F4" }}
                  onClick={() => {
                    setSelectedButton(buttons[1]);
                    setOpenModal(true);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.keyCode === 13) {
                      e.preventDefault(); // Prevent form submission on Enter key
                      setSelectedButton(buttons[1]);
                      setOpenModal(true);
                    }
                  }}
                >
                  <ButtonIcon src={FornyReseptIcon} alt="Forny resept ikon" />
                  <ButtonText>Forny resept</ButtonText>
                </StyledButton>
              )}

              {userData.selectedFeatures.kontaktLege && (
                <StyledButton
                  role="button"
                  tabIndex="0"
                  aria-label="Kontakt legekontoret knapp"
                  isLoaded={isLoaded}
                  // style={{ backgroundColor: "#e5fff0" }}
                  style={{ backgroundColor: "#FEFAF4" }}
                  onClick={() => {
                    setSelectedButton(buttons[2]);
                    setOpenModal(true);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.keyCode === 13) {
                      e.preventDefault(); // Prevent form submission on Enter key
                      setSelectedButton(buttons[2]);
                      setOpenModal(true);
                    }
                  }}
                >
                  <ButtonIcon src={KontaktLegeIcon} alt="Kontakt lege ikon" />
                  <ButtonText>Kontakt kontoret</ButtonText>
                </StyledButton>
              )}

              {userData.selectedFeatures.EKonsultasjon && (
                <StyledButton
                  role="button"
                  tabIndex="0"
                  aria-label="E-konsultasjon knapp"
                  isLoaded={isLoaded}
                  // style={{ backgroundColor: "#EEEBFF" }}
                  style={{ backgroundColor: "#FBF3FD" }}
                  onClick={() => {
                    setSelectedButton(buttons[3]);
                    setOpenModal(true);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.keyCode === 13) {
                      e.preventDefault(); // Prevent form submission on Enter key
                      setSelectedButton(buttons[3]);
                      setOpenModal(true);
                    }
                  }}
                >
                  <ButtonIcon
                    src={EKonsultasjonIcon}
                    alt="E-konsultasjon ikon"
                  />
                  <ButtonText>E-konsultasjon</ButtonText>
                </StyledButton>
              )}

              {userData.selectedFeatures.furst && (
                <StyledButton
                  role="button"
                  tabIndex="0"
                  aria-label="Furst pasient knapp"
                  isLoaded={isLoaded}
                  // style={{ backgroundColor: "#FFE5E5" }}
                  style={{ backgroundColor: "#F4F7FE" }}
                  onClick={() => {
                    setSelectedButton(buttons[4]);
                    setOpenModal(true);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.keyCode === 13) {
                      e.preventDefault(); // Prevent form submission on Enter key
                      setSelectedButton(buttons[4]);
                      setOpenModal(true);
                    }
                  }}
                >
                  <ButtonIcon src={FurstIcon} alt="Furst ikon" />
                  <ButtonText>Fürst Pasient</ButtonText>
                </StyledButton>
              )}
            </ButtonDiv>
          )}
          {userData.importantInfo.length > 0 && (
            <>
              <div
                style={{
                  // borderTop: "2px solid #e5e5e5",
                  width: "80%",
                  margin: "4rem auto 3rem",
                }}
              />
              <NyheterCarousel news={userData.importantInfo} />
            </>
          )}

          <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            {/* <Box sx={modalStyle}>
              {selectedButton && (
                <>
                  <div>
                    <StyledCloseBtn
                      src={CloseBtn}
                      alt="Close button"
                      className="close-btn"
                      onClick={() => setOpenModal(false)}
                    />
                    {selectedButton.title && (
                      <h2
                        style={{
                          marginTop: 0,
                          fontFamily: "NunitoSansRegular",
                          letterSpacing: "2px",
                          maxWidth: "85%",
                        }}
                      >
                        {selectedButton.title}
                      </h2>
                    )}
                    {selectedButton.subTitle && (
                      <p
                        style={{
                          fontSize: "1.2rem",
                          fontFamily: "NunitoSansRegular",
                          fontWeight: 600,
                          letterSpacing: "1.5px",
                        }}
                      >
                        {selectedButton.subTitle}
                      </p>
                    )}
                    {selectedButton.content && (
                      <pre
                        style={{
                          fontSize: "1.2rem",
                          fontFamily: "NunitoSansRegular",
                          letterSpacing: "1px",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {selectedButton.content}
                      </pre>
                    )}
                    {selectedButton.subContent && (
                      <p
                        style={{
                          fontSize: "1.2rem",
                          fontFamily: "NunitoSansRegular",
                          letterSpacing: "1px",
                        }}
                      >
                        {selectedButton.subContent}
                      </p>
                    )}
                    {selectedButton.info && (
                      <p
                        style={{
                          fontSize: "1.2rem",
                          fontFamily: "NunitoSansRegular",
                          letterSpacing: "1px",
                        }}
                      >
                        {selectedButton.info}
                      </p>
                    )}
                  </div>
                  {selectedButton.link && (
                    <StyledLink href={selectedButton.link} target="_blank">
                      {selectedButton.linkText}
                      <img
                        src={RightIcon}
                        alt="External link"
                        style={{
                          width: "30px",
                          height: "30px",
                          objectFit: "contain",
                        }}
                      />
                    </StyledLink>
                  )}
                </>
              )}
            </Box> */}

            <Box sx={modalStyle}>
              <div
                style={{
                  flex: 1,
                  overflowY: "auto",
                  padding: "1rem 3rem 1rem 1rem",
                }}
              >
                {selectedButton && (
                  <>
                    <StyledCloseBtn
                      role="button"
                      tabIndex="0"
                      aria-label="Lukkeknapp"
                      src={CloseBtn}
                      alt="Close button"
                      className="close-btn"
                      onClick={() => setOpenModal(false)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.keyCode === 13) {
                          e.preventDefault(); // Prevent form submission on Enter key
                          setOpenModal(false);
                        }
                      }}
                    />
                    {selectedButton.title && (
                      <h2
                        tabIndex="0"
                        style={{
                          marginTop: 0,
                          fontFamily: "NunitoSansRegular",
                          letterSpacing: "2px",
                          maxWidth: "85%",
                        }}
                      >
                        {selectedButton.title}
                      </h2>
                    )}
                    {selectedButton.subTitle && (
                      <p
                        tabIndex="0"
                        style={{
                          fontSize: "1.2rem",
                          fontFamily: "NunitoSansRegular",
                          fontWeight: 600,
                          letterSpacing: "1.5px",
                        }}
                      >
                        {selectedButton.subTitle}
                      </p>
                    )}
                    {selectedButton.content && (
                      <pre
                        tabIndex="0"
                        style={{
                          fontSize: "1.2rem",
                          fontFamily: "NunitoSansRegular",
                          letterSpacing: "1px",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {selectedButton.content}
                      </pre>
                    )}
                    {selectedButton.subContent && (
                      <p
                        tabIndex="0"
                        style={{
                          fontSize: "1.2rem",
                          fontFamily: "NunitoSansRegular",
                          letterSpacing: "1px",
                        }}
                      >
                        {selectedButton.subContent}
                      </p>
                    )}
                    {selectedButton.info && (
                      <p
                        tabIndex="0"
                        style={{
                          fontSize: "1.2rem",
                          fontFamily: "NunitoSansRegular",
                          letterSpacing: "1px",
                        }}
                      >
                        {selectedButton.info}
                      </p>
                    )}
                  </>
                )}
              </div>

              {selectedButton?.link && (
                <StyledLink
                  role="button"
                  tabIndex="0"
                  aria-label={`Link til innlogging hos Helsenorge`}
                  href={selectedButton.link}
                  target="_blank"
                  hoverColor={hoverColor}
                >
                  {selectedButton.linkText}
                  <img
                    src={RightIcon}
                    alt="External link"
                    style={{
                      width: "30px",
                      height: "30px",
                      objectFit: "contain",
                    }}
                  />
                </StyledLink>
              )}
            </Box>
          </Modal>
        </>
      ) : (
        <div
          style={{
            width: "100%",
            height: "50vh",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "2rem",
            fontFamily: "NunitoSansRegular, Arial",
          }}
        >
          Det skjedde en feil, vennligst prøv igjen!
        </div>
      )}
    </>
  );
};

export default Home;
