// import "./Kontakt.scss";
// import React, { useEffect, useState } from "react";
// import ScrollToTop from "../../Components/ScrollToTop/ScrollToTop.jsx";
// import styled, { keyframes } from "styled-components";

// const slideUp = keyframes`

//   from {
//     opacity: 0;
//     transform: translateY(10px);
//   }
//   to {
//     opacity: 1;
//     transform: translateY(0);
//   }`;

// const FooterInfo = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-evenly;
//   align-items: center;
//   gap: 2rem;
//   padding: 3rem 0;

//   > * {
//     animation-delay: 2s;
//   }

//   > *:nth-child(1) {
//     animation-delay: 0.2s;
//   }

//   > *:nth-child(2) {
//     animation-delay: 0.4s;
//   }

//   > *:nth-child(3) {
//     animation-delay: 0.6s;
//   }

//   > *:nth-child(4) {
//     animation-delay: 0.8s;
//   }

//   > *:nth-child(5) {
//     animation-delay: 1s;
//   }

//   > *:nth-child(6) {
//     animation-delay: 1.2s;
//   }
//   > *:nth-child(7) {
//     animation-delay: 1.4s;
//   }
//   > *:nth-child(8) {
//     animation-delay: 1.6s;
//   }
//   > *:nth-child(9) {
//     animation-delay: 1.8s;
//   }
// `;
// const ContactInfo = styled.div`
//   width: 40%;
//   min-width: 300px;
//   max-width: 100%;
//   textwrap: wrap;
//   overflow: hidden;

//   opacity: 0;
//   animation: ${({ isLoaded }) => (isLoaded ? slideUp : "none")} 1s ease forwards;

//   h4 {
//     font-family: "NunitoSansMedium", sans-serif;
//     font-size: 1.2rem;
//     margin-top: 0;
//     margin-bottom: 10px;
//   }

//   p {
//     font-size: 1.05rem;
//     margin: 5px 0;
//   }
// `;
// const MapContainer = styled.div`
//   width: 40%;

//   min-width: 300px;

//   opacity: 0;
//   animation: ${({ isLoaded }) => (isLoaded ? slideUp : "none")} 1s ease forwards;

//   iframe {
//     width: 85%;
//     max-width: 400px;
//     height: 230px;
//     border: none;
//     border-radius: 8px;
//     box-shadow: 0 1.2px 3px 0.2px rgba(0, 0, 0, 0.3);
//   }
// `;

// // Styled Components
// const FooterContainer = styled.footer`
//   // background-color: #f9f9f9;
//   padding: 0 20px;
//   text-align: center;

//   @media (min-width: 1600px) {
//     border-radius: 10px;
//   }
// `;

// function Kontakt() {
//   const [isLoaded, setIsLoaded] = useState(false);

//   const [isMobile, setIsMobile] = useState(false);

//   const [userData, setUserData] = useState(null);

//   ScrollToTop();

//   // useEffect(() => {
//   //   const fetchUserData = sessionStorage.getItem("userData");
//   //   const parsedUserData = JSON.parse(fetchUserData);
//   //   setUserData(JSON.parse(parsedUserData.acf.clinicdata));
//   //   // console.log(
//   //   //   "User data fetched and stored:",
//   //   //   JSON.parse(parsedUserData.acf.clinicdata)
//   //   // );
//   //   setIsLoaded(true);
//   // }, []);

//   useEffect(() => {
//     const fetchUserData = sessionStorage.getItem("userData");

//     let parsedUserData = null;

//     if (fetchUserData) {
//       parsedUserData = JSON.parse(fetchUserData);
//     }

//     if (
//       fetchUserData &&
//       parsedUserData &&
//       parsedUserData?.acf &&
//       parsedUserData?.acf?.clinicdata
//     ) {
//       setUserData(JSON.parse(parsedUserData.acf.clinicdata));
//       setIsLoaded(true);
//     }
//   }, []);

//   useEffect(() => {
//     // Detect if user is on a mobile device
//     const handleResize = () => {
//       if (window.innerWidth <= 768) {
//         setIsMobile(true);
//       } else {
//         setIsMobile(false);
//       }
//     };

//     handleResize(); // check initially
//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   const handleClick = () => {
//     if (isMobile) {
//       // Trigger dial functionality
//       window.location.href = "tel:12345678";
//     }
//   };

//   const handleEmailClick = () => {
//     // Trigger email functionality
//     window.location.href = "mailto:post@test.no";
//   };

//   return (
//     <>
//       {isLoaded && userData && (
//         <FooterContainer>
//           <FooterInfo>
//             {userData.openingTimes.length > 0 && (
//               <ContactInfo isLoaded={isLoaded}>
//                 <h4>Åpningstider</h4>
//                 {userData.openingTimes.map((time, index) => (
//                   <>
//                     <pre key={index} style={{ marginBottom: "15px" }}>
//                       <span style={{ fontWeight: 600 }}>{time.title}</span>
//                       <br />
//                       {time.hours}
//                     </pre>
//                   </>
//                 ))}
//               </ContactInfo>
//             )}

//             {userData.doctorsOffice.googleMaps && (
//               <MapContainer isLoaded={isLoaded}>
//                 <iframe
//                   title="Stranden Legesenter Location"
//                   src={userData.doctorsOffice.googleMaps}
//                   allowFullScreen=""
//                   loading="lazy"
//                 ></iframe>
//               </MapContainer>
//             )}

//             {(userData.contactInfo.phone ||
//               userData.contactInfo.email ||
//               userData.contactInfo.fax ||
//               userData.contactInfo.postboxAddress ||
//               userData.contactInfo.postboxZipcity) && (
//               <ContactInfo isLoaded={isLoaded}>
//                 <h4>Kontakt</h4>
//                 {userData.contactInfo.phone && (
//                   <p onClick={handleClick} style={{ marginBottom: "5px" }}>
//                     <span style={{ fontWeight: 600 }}>Tlf:</span>{" "}
//                     {userData.contactInfo.phone}
//                   </p>
//                 )}
//                 {userData.contactInfo.email && (
//                   <p
//                     onClick={handleEmailClick}
//                     style={{ cursor: "pointer", marginBottom: "5px" }}
//                   >
//                     <span style={{ fontWeight: 600 }}>E-post:</span>{" "}
//                     {userData.contactInfo.email}
//                   </p>
//                 )}

//                 {userData.contactInfo.fax && (
//                   <p style={{ marginBottom: "5px" }}>
//                     <span style={{ fontWeight: 600 }}>Faks:</span>{" "}
//                     {userData.contactInfo.fax}
//                   </p>
//                 )}

//                 {userData.contactInfo.postboxAddress && (
//                   <p style={{ marginBottom: "5px" }}>
//                     <span style={{ fontWeight: 600 }}>Post:</span>{" "}
//                     {userData.contactInfo.postboxAddress}
//                   </p>
//                 )}
//                 {userData.contactInfo.postboxZipcity && (
//                   <p>{userData.contactInfo.postboxZipcity}</p>
//                 )}
//               </ContactInfo>
//             )}
//             {(userData.doctorsOffice.address ||
//               userData.doctorsOffice.zipcity) && (
//               <ContactInfo isLoaded={isLoaded}>
//                 <h4>Besøk</h4>
//                 {userData.doctorsOffice.address && (
//                   <p>{userData.doctorsOffice.address}</p>
//                 )}
//                 {userData.doctorsOffice.zipcity && (
//                   <p>{userData.doctorsOffice.zipcity}</p>
//                 )}
//               </ContactInfo>
//             )}
//           </FooterInfo>
//         </FooterContainer>
//       )}

//       {!isLoaded && (
//         <div
//           style={{
//             width: "100%",
//             height: "50vh",
//             textAlign: "center",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             fontSize: "2rem",
//             fontFamily: "NunitoSansRegular, Arial",
//           }}
//         >
//           Det skjedde en feil, vennligst prøv igjen!
//         </div>
//       )}
//     </>
//   );
// }
// export default Kontakt;

import "./Kontakt.scss";
import React, { useEffect, useState } from "react";
import ScrollToTop from "../../Components/ScrollToTop/ScrollToTop.jsx";
import styled, { keyframes } from "styled-components";

const slideUp = keyframes`

  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }`;

const FooterInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 4rem 2rem;
  padding: 3rem 0;

  > * {
    animation-delay: 2s;
  }

  > *:nth-child(1) {
    animation-delay: 0.2s;
  }

  > *:nth-child(2) {
    animation-delay: 0.4s;
  }

  > *:nth-child(3) {
    animation-delay: 0.6s;
  }

  > *:nth-child(4) {
    animation-delay: 0.8s;
  }

  > *:nth-child(5) {
    animation-delay: 1s;
  }

  > *:nth-child(6) {
    animation-delay: 1.2s;
  }
  > *:nth-child(7) {
    animation-delay: 1.4s;
  }
  > *:nth-child(8) {
    animation-delay: 1.6s;
  }
  > *:nth-child(9) {
    animation-delay: 1.8s;
  }
`;
const ContactInfo = styled.div`
  width: 40%;
  min-width: 300px;
  max-width: 100%;
  textwrap: wrap;
  overflow: hidden;

  opacity: 0;
  animation: ${({ isLoaded }) => (isLoaded ? slideUp : "none")} 1s ease forwards;

  h4 {
    font-family: "NunitoSansMedium", sans-serif;
    font-size: 1.2rem;
    margin-top: 0;
    margin-bottom: 10px;
  }

  p {
    font-size: 1.05rem;
    margin: 5px 0;
  }
`;
const MapContainer = styled.div`
  width: 40%;

  min-width: 300px;

  opacity: 0;
  animation: ${({ isLoaded }) => (isLoaded ? slideUp : "none")} 1s ease forwards;

  iframe {
    width: 85%;
    max-width: 400px;
    height: 200px;
    border: none;
    border-radius: 8px;
    box-shadow: 0 1.2px 3px 0.2px rgba(0, 0, 0, 0.3);
  }
`;

// Styled Components
const FooterContainer = styled.footer`
  // background-color: #f9f9f9;
  padding: 0 20px;
  text-align: center;

  @media (min-width: 1600px) {
    border-radius: 10px;
  }
`;

const Kontakt = ({ userData }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const [isMobile, setIsMobile] = useState(false);

  ScrollToTop();

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    // Detect if user is on a mobile device
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    handleResize(); // check initially
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClick = () => {
    if (isMobile) {
      // Trigger dial functionality
      window.location.href = "tel:12345678";
    }
  };

  const handleEmailClick = () => {
    // Trigger email functionality
    window.location.href = "mailto:post@test.no";
  };

  return (
    <>
      {userData ? (
        <FooterContainer>
          <FooterInfo>
            {userData.openingTimes.length > 0 && (
              <ContactInfo isLoaded={isLoaded}>
                <h4>Åpningstider</h4>
                {userData.openingTimes.map((time, index) => (
                  <>
                    <pre key={index} style={{ marginBottom: "15px" }}>
                      <span style={{ fontWeight: 600 }}>{time.title}</span>
                      <br />
                      {time.hours}
                    </pre>
                  </>
                ))}
              </ContactInfo>
            )}

            {userData.doctorsOffice.googleMaps && (
              <MapContainer isLoaded={isLoaded}>
                <iframe
                  title="Stranden Legesenter Location"
                  src={userData.doctorsOffice.googleMaps}
                  allowFullScreen=""
                  loading="lazy"
                ></iframe>
              </MapContainer>
            )}

            {(userData.contactInfo.phone ||
              userData.contactInfo.email ||
              userData.contactInfo.fax ||
              userData.contactInfo.postboxAddress ||
              userData.contactInfo.postboxZipcity) && (
              <ContactInfo isLoaded={isLoaded}>
                <h4>Kontakt</h4>
                {userData.contactInfo.phone && (
                  <p onClick={handleClick} style={{ marginBottom: "5px" }}>
                    <span style={{ fontWeight: 600 }}>Tlf:</span>{" "}
                    {userData.contactInfo.phone}
                  </p>
                )}
                {userData.contactInfo.email && (
                  <p
                    onClick={handleEmailClick}
                    style={{ cursor: "pointer", marginBottom: "5px" }}
                  >
                    <span style={{ fontWeight: 600 }}>E-post:</span>{" "}
                    {userData.contactInfo.email}
                  </p>
                )}

                {userData.contactInfo.fax && (
                  <p style={{ marginBottom: "5px" }}>
                    <span style={{ fontWeight: 600 }}>Faks:</span>{" "}
                    {userData.contactInfo.fax}
                  </p>
                )}

                {userData.contactInfo.postboxAddress && (
                  <p style={{ marginBottom: "5px" }}>
                    <span style={{ fontWeight: 600 }}>Post:</span>{" "}
                    {userData.contactInfo.postboxAddress}
                  </p>
                )}
                {userData.contactInfo.postboxZipcity && (
                  <p>{userData.contactInfo.postboxZipcity}</p>
                )}
              </ContactInfo>
            )}
            {(userData.doctorsOffice.address ||
              userData.doctorsOffice.zipcity) && (
              <ContactInfo isLoaded={isLoaded}>
                <h4>Besøk</h4>
                {userData.doctorsOffice.address && (
                  <p>{userData.doctorsOffice.address}</p>
                )}
                {userData.doctorsOffice.zipcity && (
                  <p>{userData.doctorsOffice.zipcity}</p>
                )}
              </ContactInfo>
            )}
          </FooterInfo>
        </FooterContainer>
      ) : (
        <div
          style={{
            width: "100%",
            height: "50vh",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "2rem",
            fontFamily: "NunitoSansRegular, Arial",
          }}
        >
          Det skjedde en feil, vennligst prøv igjen!
        </div>
      )}
    </>
  );
};
export default Kontakt;
