// import "./Informasjon.scss";
// import React, { useState, useEffect } from "react";
// import ScrollToTop from "../../Components/ScrollToTop/ScrollToTop.jsx";
// import styled, { keyframes } from "styled-components";
// import NyheterCarousel from "../../Components/NyheterCarousel/NyheterCarousel.jsx";
// import ViktigCarousel from "../../Components/ViktigCarousel/ViktigCarousel.jsx";

// // Keyframes for sliding animation

// const slideRight = keyframes`
//   from {
//     opacity: 0;
//     transform: translateX(-30px);
//   }
//   to {
//     opacity: 1;
//     transform: translateY(0);
//   }
// `;

// const slideDown = keyframes`
//   from {
//     opacity: 0;
//     transform: translateY(-30px);
//   }
//   to {
//     opacity: 1;
//     transform: translateY(0);
//   }
// `;

// const slideUp = keyframes`

//   from {
//     opacity: 0;
//     transform: translateY(30px);
//   }
//   to {
//     opacity: 1;
//     transform: translateY(0);
//   }
// `;

// const slideLeft = keyframes`
//   from {
//     opacity: 0;
//     transform: translateX(30px);
//   }
//   to {
//     opacity: 1;
//     transform: translateY(0);
//   }
// `;

// const CarouselDiv = styled.div`
//   margin-top: 2rem;
//   opacity: 0;
//   animation: ${({ isLoaded }) => (isLoaded ? slideUp : "none")} 1s ease forwards;
// `;

// // Container for all cards
// const CardContainer = styled.div`
//   // display: grid;
//   // grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
//   display: flex;
//   justify-content: center;
//   flex-wrap: wrap;
//   gap: 2rem;
//   padding: 20px;
//   max-width: 1200px;
//   margin: 0 auto;

//   /* Targeting the children */
//   > * {
//     animation-delay: 0.2s;
//   }

//   > *:nth-child(1) {
//     animation-delay: 0.2s;
//   }

//   > *:nth-child(2) {
//     animation-delay: 0.4s;
//   }

//   > *:nth-child(3) {
//     animation-delay: 0.6s;
//   }

//   > *:nth-child(4) {
//     animation-delay: 0.8s;
//   }

//   > *:nth-child(5) {
//     animation-delay: 1s;
//   }

//   > *:nth-child(6) {
//     animation-delay: 1.2s;
//   }
//   > *:nth-child(7) {
//     animation-delay: 1.4s;
//   }
//   > *:nth-child(8) {
//     animation-delay: 1.6s;
//   }
//   > *:nth-child(9) {
//     animation-delay: 1.8s;
//   }
// `;

// const StyledH1 = styled.h1`
//   font-family: "NunitoSansLight", sans-serif;
//   text-align: center;
//   font-size: 1.9rem;

//   opacity: 0;
//   animation: ${({ isLoaded }) => (isLoaded ? slideUp : "none")} 1s ease forwards;
//   animation-delay: 0.4s;
// `;

// const Card = styled.a`
//   display: flex;
//   width: 300px;
//   flex-direction: column;
//   justify-content: space-between;
//   // border: 1px solid #ddd;
//   border-radius: 8px;
//   padding: 0.7rem 1.5rem 1rem;
//   text-decoration: none;
//   color: #333;
//   transition: transform 0.3s ease, box-shadow 0.3s ease;
//   box-shadow: 0 1.2px 3px 0.2px rgba(0, 0, 0, 0.3);
//   overflow: hidden;
//   background-color: #fdfef450;

//   opacity: 0;
//   animation: ${({ isLoaded }) => (isLoaded ? slideUp : "none")} 1s ease forwards;
//   animation-delay: 0.6s;

//   &:hover {
//     transform: translateY(-5px);
//     box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
//   }
// `;

// const CardTitle = styled.h3`
//   font-size: 18px;
//   font-weight: bold;
//   margin-bottom: 10px;
//   text-overflow: ellipsis;
//   overflow: hidden;
//   white-space: nowrap;
// `;

// const CardDescription = styled.p`
//   font-size: 14px;
//   line-height: 1.6;
//   margin-bottom: 10px;
//   color: #555;
//   text-overflow: ellipsis;
//   overflow: hidden;
//   display: -webkit-box;
//   -webkit-line-clamp: 2;
//   -webkit-box-orient: vertical;
// `;

// const StyledImportantDiv = styled.div`
//   margin: 1rem 0 4rem;
//   opacity: 0;
//   animation: ${({ isLoaded }) => (isLoaded ? slideUp : "none")} 1s ease forwards;
//   animation-delay: 0.1s;
// `;

// function Informasjon() {
//   const [isLoaded, setIsLoaded] = useState(false);
//   const [isNotLoaded, setIsNotLoaded] = useState(false);
//   const [userData, setUserData] = useState(null);

//   ScrollToTop();

//   // useEffect(() => {
//   //   const fetchUserData = sessionStorage.getItem("userData");
//   //   const parsedUserData = JSON.parse(fetchUserData);
//   //   setUserData(JSON.parse(parsedUserData.acf.clinicdata));

//   //   setIsLoaded(true);
//   // }, []);

//   useEffect(() => {
//     const fetchUserData = sessionStorage.getItem("userData");
//     let parsedUserData = null;

//     if (fetchUserData) {
//       parsedUserData = JSON.parse(fetchUserData);
//     }

//     if (
//       fetchUserData &&
//       parsedUserData &&
//       parsedUserData?.acf &&
//       parsedUserData?.acf?.clinicdata
//     ) {
//       setUserData(JSON.parse(parsedUserData.acf.clinicdata));
//       setIsLoaded(true);
//     } else {
//       setIsNotLoaded(true);
//     }
//   }, []);

//   return (
//     <>
//       {isLoaded && userData && (
//         <>
//           {userData.importantInfo.length > 0 && (
//             <StyledImportantDiv isLoaded={isLoaded}>
//               <ViktigCarousel news={userData.importantInfo} />
//             </StyledImportantDiv>
//           )}
//           {userData.importantInfo.length > 0 && (
//             <CarouselDiv isLoaded={isLoaded}>
//               <NyheterCarousel news={userData.importantInfo} />
//             </CarouselDiv>
//           )}

//           {userData.usefulLinks.length > 0 && (
//             <>
//               <StyledH1 tabIndex="0" isLoaded={isLoaded}>
//                 Nyttige lenker
//               </StyledH1>

//               <CardContainer style={{ marginBottom: "4rem" }}>
//                 {userData.usefulLinks.map((link, index) => (
//                   <Card
//                     role="button"
//                     tabIndex="0"
//                     aria-label={`Link til ${link.title}`}
//                     key={index}
//                     isLoaded={isLoaded}
//                     href={link.url}
//                     target="_blank"
//                   >
//                     <CardTitle>{link.title}</CardTitle>
//                     <CardDescription tabIndex="0">{link.text}</CardDescription>
//                   </Card>
//                 ))}
//               </CardContainer>
//             </>
//           )}
//         </>
//       )}

//       {!isLoaded && isNotLoaded && (
//         <div
//           style={{
//             width: "100%",
//             height: "50vh",
//             textAlign: "center",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             fontSize: "2rem",
//             fontFamily: "NunitoSansRegular, Arial",
//           }}
//         >
//           Det skjedde en feil, vennligst prøv igjen!
//         </div>
//       )}
//     </>
//   );
// }

// export default Informasjon;

import "./Informasjon.scss";
import React, { useState, useEffect } from "react";
import ScrollToTop from "../../Components/ScrollToTop/ScrollToTop.jsx";
import styled, { keyframes } from "styled-components";
import NyheterCarousel from "../../Components/NyheterCarousel/NyheterCarousel.jsx";
import ViktigCarousel from "../../Components/ViktigCarousel/ViktigCarousel.jsx";

// Keyframes for sliding animation

const slideUp = keyframes`

  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const CarouselDiv = styled.div`
  margin-top: 2rem;
  opacity: 0;
  animation: ${({ isLoaded }) => (isLoaded ? slideUp : "none")} 1s ease forwards;
`;

// Container for all cards
const CardContainer = styled.div`
  // display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;

  /* Targeting the children */
  > * {
    animation-delay: 0.2s;
  }

  > *:nth-child(1) {
    animation-delay: 0.2s;
  }

  > *:nth-child(2) {
    animation-delay: 0.4s;
  }

  > *:nth-child(3) {
    animation-delay: 0.6s;
  }

  > *:nth-child(4) {
    animation-delay: 0.8s;
  }

  > *:nth-child(5) {
    animation-delay: 1s;
  }

  > *:nth-child(6) {
    animation-delay: 1.2s;
  }
  > *:nth-child(7) {
    animation-delay: 1.4s;
  }
  > *:nth-child(8) {
    animation-delay: 1.6s;
  }
  > *:nth-child(9) {
    animation-delay: 1.8s;
  }
`;

const StyledH1 = styled.h1`
  font-family: "NunitoSansLight", sans-serif;
  text-align: center;
  font-size: 1.9rem;

  opacity: 0;
  animation: ${({ isLoaded }) => (isLoaded ? slideUp : "none")} 1s ease forwards;
  animation-delay: 0.4s;
`;

const Card = styled.a`
  display: flex;
  width: 300px;
  flex-direction: column;
  justify-content: space-between;
  // border: 1px solid #ddd;
  border-radius: 8px;
  padding: 0.7rem 1.5rem 1rem;
  text-decoration: none;
  color: #333;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 1.2px 3px 0.2px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  background-color: #fdfef450;

  opacity: 0;
  animation: ${({ isLoaded }) => (isLoaded ? slideUp : "none")} 1s ease forwards;
  animation-delay: 0.6s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
`;

const CardTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const CardDescription = styled.p`
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 10px;
  color: #555;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const StyledImportantDiv = styled.div`
  margin: 1rem 0 4rem;
  opacity: 0;
  animation: ${({ isLoaded }) => (isLoaded ? slideUp : "none")} 1s ease forwards;
  animation-delay: 0.1s;
`;

const Informasjon = ({ userData }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  ScrollToTop();

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <>
      {userData ? (
        <>
          {userData.importantInfo.length > 0 && (
            <StyledImportantDiv isLoaded={isLoaded}>
              <ViktigCarousel news={userData.importantInfo} />
            </StyledImportantDiv>
          )}
          {userData.importantInfo.length > 0 && (
            <CarouselDiv isLoaded={isLoaded}>
              <NyheterCarousel news={userData.importantInfo} />
            </CarouselDiv>
          )}

          {userData.usefulLinks.length > 0 && (
            <>
              <StyledH1 tabIndex="0" isLoaded={isLoaded}>
                Nyttige lenker
              </StyledH1>

              <CardContainer style={{ marginBottom: "4rem" }}>
                {userData.usefulLinks.map((link, index) => (
                  <Card
                    role="button"
                    tabIndex="0"
                    aria-label={`Link til ${link.title}`}
                    key={index}
                    isLoaded={isLoaded}
                    href={link.url}
                    target="_blank"
                  >
                    <CardTitle>{link.title}</CardTitle>
                    <CardDescription tabIndex="0">{link.text}</CardDescription>
                  </Card>
                ))}
              </CardContainer>
            </>
          )}
        </>
      ) : (
        <div
          style={{
            width: "100%",
            height: "50vh",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "2rem",
            fontFamily: "NunitoSansRegular, Arial",
          }}
        >
          Det skjedde en feil, vennligst prøv igjen!
        </div>
      )}
    </>
  );
};

export default Informasjon;
