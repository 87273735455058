// import React, { useEffect, useState } from "react";
// import ScrollToTop from "../../Components/ScrollToTop/ScrollToTop.jsx";
// import styled, { keyframes } from "styled-components";

// // Keyframes for sliding animation

// const slideRight = keyframes`
//   from {
//     opacity: 0;
//     transform: translateX(-30px);
//   }
//   to {
//     opacity: 1;
//     transform: translateY(0);
//   }
// `;

// const slideDown = keyframes`
//   from {
//     opacity: 0;
//     transform: translateY(-30px);
//   }
//   to {
//     opacity: 1;
//     transform: translateY(0);
//   }
// `;

// const slideUp = keyframes`

//   from {
//     opacity: 0;
//     transform: translateY(30px);
//   }
//   to {
//     opacity: 1;
//     transform: translateY(0);
//   }
// `;

// const slideLeft = keyframes`
//   from {
//     opacity: 0;
//     transform: translateX(30px);
//   }
//   to {
//     opacity: 1;
//     transform: translateY(0);
//   }
// `;

// const StyledP = styled.p`
//   font-size: 0.9rem;
//   margin: 0;
//   margin-bottom: -4px;
//   color: #999;
// `;

// function Ansatte() {
//   const [isLoaded, setIsLoaded] = useState(false);
//   const [isNotLoaded, setIsNotLoaded] = useState(false);

//   const [userData, setUserData] = useState(null);

//   ScrollToTop();

//   // useEffect(() => {
//   //   const fetchUserData = sessionStorage.getItem("userData");
//   //   const parsedUserData = JSON.parse(fetchUserData);
//   //   setUserData(JSON.parse(parsedUserData.acf.clinicdata));

//   //   setIsLoaded(true);
//   // }, []);

//   useEffect(() => {
//     const fetchUserData = sessionStorage.getItem("userData");

//     let parsedUserData = null;

//     if (fetchUserData) {
//       parsedUserData = JSON.parse(fetchUserData);
//     }

//     if (
//       fetchUserData &&
//       parsedUserData &&
//       parsedUserData?.acf &&
//       parsedUserData?.acf?.clinicdata
//     ) {
//       setUserData(JSON.parse(parsedUserData.acf.clinicdata));
//       setIsLoaded(true);
//     } else {
//       setIsNotLoaded(true);
//     }
//   }, []);

//   return (
//     <>
//       {isLoaded && userData && (
//         <>
//           {userData.employees.length > 0 && (
//             <EmployeeList>
//               {userData.employees
//                 .sort(
//                   (a, b) => (a.description ? 1 : 0) - (b.description ? 1 : 0)
//                 ) // Sorting the employees
//                 .map((employee, index) => (
//                   <EmployeeCard isLoaded={isLoaded} key={index}>
//                     <div style={{ display: "flex" }}>
//                       <EmployeeImage
//                         tabIndex="0"
//                         style={{
//                           borderRadius: employee.description
//                             ? "0 0 5px 0"
//                             : "0",
//                           boxShadow: employee.description
//                             ? "0 1.2px 3px 0.2px rgba(0, 0, 0, 0.2)"
//                             : "none",
//                         }}
//                         src={
//                           employee.image
//                             ? employee.image
//                             : "https://ventral.no/legenettside_backend/wp-content/uploads/2025/03/placeholderAnsatt.png"
//                         }
//                         alt={
//                           employee.name ? employee.name : "Placeholder image"
//                         }
//                       />

//                       <EmployeeInfo>
//                         {employee.name && (
//                           <EmployeeName tabIndex="0">
//                             {employee.name}
//                           </EmployeeName>
//                         )}
//                         {employee.jobTitle && (
//                           <EmployeeProfession tabIndex="0">
//                             {employee.jobTitle}
//                           </EmployeeProfession>
//                         )}

//                         {employee.specialist && (
//                           <>
//                             <StyledP
//                               tabIndex="0"
//                               style={{
//                                 marginBottom: -5,
//                                 marginTop: 5,
//                               }}
//                             >
//                               Spesialist i
//                             </StyledP>
//                             <EmployeeSpecialization tabIndex="0">
//                               {employee.specialist}
//                             </EmployeeSpecialization>
//                           </>
//                         )}
//                       </EmployeeInfo>
//                     </div>
//                     {employee.description && (
//                       <EmployeeSpecialization
//                         tabIndex="0"
//                         style={{ padding: "15px", color: "#666" }}
//                       >
//                         {employee.description}
//                       </EmployeeSpecialization>
//                     )}
//                   </EmployeeCard>
//                 ))}
//             </EmployeeList>
//           )}
//         </>
//       )}

//       {!isLoaded && isNotLoaded && (
//         <div
//           style={{
//             width: "100%",
//             height: "50vh",
//             textAlign: "center",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             fontSize: "2rem",
//             fontFamily: "NunitoSansRegular, Arial",
//           }}
//         >
//           Det skjedde en feil, vennligst prøv igjen!
//         </div>
//       )}
//     </>
//   );
// }

// const EmployeeList = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   width: 90%;

//   margin: 2rem auto 3rem;
//   justify-content: space-evenly;

//   /* Targeting the children */
//   > * {
//     animation-delay: 0.2s;
//   }

//   > *:nth-child(1) {
//     animation-delay: 0.2s;
//   }

//   > *:nth-child(2) {
//     animation-delay: 0.4s;
//   }

//   > *:nth-child(3) {
//     animation-delay: 0.6s;
//   }

//   > *:nth-child(4) {
//     animation-delay: 0.8s;
//   }

//   > *:nth-child(5) {
//     animation-delay: 1s;
//   }

//   > *:nth-child(6) {
//     animation-delay: 1.2s;
//   }
//   > *:nth-child(7) {
//     animation-delay: 1.4s;
//   }
//   > *:nth-child(8) {
//     animation-delay: 1.6s;
//   }
//   > *:nth-child(9) {
//     animation-delay: 1.8s;
//   }

//   @media (max-width: 599px) {
//     width: 98%;
//   }
// `;

// const EmployeeCard = styled.div`
//   display: flex;
//   flex-direction: column;
//   min-width: 320px;
//   width: fit-content;
//   max-width: 400px;
//   min-height: 150px;
//   height: fit-content;
//   // border: 1px solid #ddd;
//   box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
//   box-shadow: 0 1.2px 3px 0.2px rgba(0, 0, 0, 0.3);

//   border-radius: 8px;
//   overflow: hidden;
//   margin: 1rem;

//   opacity: 0;
//   animation: ${({ isLoaded }) => (isLoaded ? slideUp : "none")} 1s ease forwards;
// `;

// const EmployeeImage = styled.img`
//   width: 105px;
//   height: 150px;
//   object-fit: cover;
// `;

// const EmployeeInfo = styled.div`
//   padding: 15px;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-evenly;
// `;

// const EmployeeName = styled.h3`
//   font-size: 1.2rem;
//   margin: 0;
//   margin-top: -5px;
//   color: #333;
// `;

// const EmployeeProfession = styled.pre`
//   font-size: 1rem;
//   margin: 0 5px 5px 0;
//   color: #666;
//   white-space: pre-wrap;
// `;

// const EmployeeSpecialization = styled.pre`
//   font-size: 0.9rem;
//   margin: 0;
//   color: #999;
//   white-space: pre-wrap;
// `;

// export default Ansatte;

import React, { useEffect, useState } from "react";
import ScrollToTop from "../../Components/ScrollToTop/ScrollToTop.jsx";
import styled, { keyframes } from "styled-components";

// Keyframes for sliding animation

const slideRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideUp = keyframes`

  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledP = styled.p`
  font-size: 0.9rem;
  margin: 0;
  margin-bottom: -4px;
  color: #999;
`;

const Ansatte = ({ userData }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  ScrollToTop();

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <>
      {userData ? (
        <>
          {userData.employees.length > 0 && (
            <EmployeeList>
              {userData.employees
                .sort(
                  (a, b) => (a.description ? 1 : 0) - (b.description ? 1 : 0)
                ) // Sorting the employees
                .map((employee, index) => (
                  <EmployeeCard isLoaded={isLoaded} key={index}>
                    <div style={{ display: "flex" }}>
                      <EmployeeImage
                        tabIndex="0"
                        style={{
                          borderRadius: employee.description
                            ? "0 0 5px 0"
                            : "0",
                          boxShadow: employee.description
                            ? "0 1.2px 3px 0.2px rgba(0, 0, 0, 0.2)"
                            : "none",
                        }}
                        src={
                          employee.image
                            ? employee.image
                            : "https://ventral.no/legenettside_backend/wp-content/uploads/2025/03/placeholderAnsatt.png"
                        }
                        alt={
                          employee.name ? employee.name : "Placeholder image"
                        }
                      />

                      <EmployeeInfo>
                        {employee.name && (
                          <EmployeeName tabIndex="0">
                            {employee.name}
                          </EmployeeName>
                        )}
                        {employee.jobTitle && (
                          <EmployeeProfession tabIndex="0">
                            {employee.jobTitle}
                          </EmployeeProfession>
                        )}

                        {employee.specialist && (
                          <>
                            <StyledP
                              tabIndex="0"
                              style={{
                                marginBottom: -5,
                                marginTop: 5,
                              }}
                            >
                              Spesialist i
                            </StyledP>
                            <EmployeeSpecialization tabIndex="0">
                              {employee.specialist}
                            </EmployeeSpecialization>
                          </>
                        )}
                      </EmployeeInfo>
                    </div>
                    {employee.description && (
                      <EmployeeSpecialization
                        tabIndex="0"
                        style={{ padding: "15px", color: "#666" }}
                      >
                        {employee.description}
                      </EmployeeSpecialization>
                    )}
                  </EmployeeCard>
                ))}
            </EmployeeList>
          )}
        </>
      ) : (
        <div
          style={{
            width: "100%",
            height: "50vh",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "2rem",
            fontFamily: "NunitoSansRegular, Arial",
          }}
        >
          Det skjedde en feil, vennligst prøv igjen!
        </div>
      )}
    </>
  );
};

const EmployeeList = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 90%;

  margin: 2rem auto 3rem;
  justify-content: space-evenly;

  /* Targeting the children */
  > * {
    animation-delay: 0.2s;
  }

  > *:nth-child(1) {
    animation-delay: 0.2s;
  }

  > *:nth-child(2) {
    animation-delay: 0.4s;
  }

  > *:nth-child(3) {
    animation-delay: 0.6s;
  }

  > *:nth-child(4) {
    animation-delay: 0.8s;
  }

  > *:nth-child(5) {
    animation-delay: 1s;
  }

  > *:nth-child(6) {
    animation-delay: 1.2s;
  }
  > *:nth-child(7) {
    animation-delay: 1.4s;
  }
  > *:nth-child(8) {
    animation-delay: 1.6s;
  }
  > *:nth-child(9) {
    animation-delay: 1.8s;
  }

  @media (max-width: 599px) {
    width: 98%;
  }
`;

const EmployeeCard = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 320px;
  width: fit-content;
  max-width: 400px;
  min-height: 150px;
  height: fit-content;
  // border: 1px solid #ddd;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  box-shadow: 0 1.2px 3px 0.2px rgba(0, 0, 0, 0.3);

  border-radius: 8px;
  overflow: hidden;
  margin: 1rem;

  opacity: 0;
  animation: ${({ isLoaded }) => (isLoaded ? slideUp : "none")} 1s ease forwards;
`;

const EmployeeImage = styled.img`
  width: 105px;
  height: 150px;
  object-fit: cover;
`;

const EmployeeInfo = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const EmployeeName = styled.h3`
  font-size: 1.2rem;
  margin: 0;
  margin-top: -5px;
  color: #333;
`;

const EmployeeProfession = styled.pre`
  font-size: 1rem;
  margin: 0 5px 5px 0;
  color: #666;
  white-space: pre-wrap;
`;

const EmployeeSpecialization = styled.pre`
  font-size: 0.9rem;
  margin: 0;
  color: #999;
  white-space: pre-wrap;
`;

export default Ansatte;
