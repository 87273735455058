// import "./App.css";
// import { Routes, Route } from "react-router-dom";
// import Home from "./pages/Home/Home.jsx";
// import Nyttig from "./pages/Informasjon/Informasjon.jsx";
// import Betaling from "./pages/Priser/Priser.jsx";
// import Ansatte from "./pages/Ansatte/Ansatte.jsx";
// import Kontakt from "./pages/Kontakt/Kontakt.jsx";
// import RouteNotFound from "./pages/RouteNotFound/RouteNotFound.jsx";
// import Layout from "./Components/Layouts/Layout.jsx";

// function App() {
//   return (
//     <div className="App">
//       <Routes>
//         <Route path="/" element={<Layout />}>
//           <Route index element={<Home />} />
//           <Route path="nyttig" element={<Nyttig />} />
//           <Route path="betaling" element={<Betaling />} />
//           <Route path="ansatte" element={<Ansatte />} />
//           <Route path="kontakt" element={<Kontakt />} />
//           <Route path="*" element={<RouteNotFound />} />
//         </Route>
//       </Routes>
//     </div>
//   );
// }

// export default App;

import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home.jsx";
import Nyttig from "./pages/Informasjon/Informasjon.jsx";
import Betaling from "./pages/Priser/Priser.jsx";
import Ansatte from "./pages/Ansatte/Ansatte.jsx";
import Kontakt from "./pages/Kontakt/Kontakt.jsx";
import RouteNotFound from "./pages/RouteNotFound/RouteNotFound.jsx";
import Layout from "./Components/Layouts/Layout.jsx";

function App({ userData, takster }) {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout userData={userData} />}>
          <Route index element={<Home userData={userData} />} />
          <Route path="nyttig" element={<Nyttig userData={userData} />} />
          <Route
            path="betaling"
            element={<Betaling userData={userData} takster={takster} />}
          />
          <Route path="ansatte" element={<Ansatte userData={userData} />} />
          <Route path="kontakt" element={<Kontakt userData={userData} />} />
          <Route path="*" element={<RouteNotFound userData={userData} />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
