// import React, { useEffect, useState } from "react";
// import PropTypes from "prop-types";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
// import Box from "@mui/material/Box";
// import styled, { keyframes } from "styled-components";

// import { Link, useNavigate } from "react-router-dom";
// import CallIcon from "@mui/icons-material/Call";
// import MailOutlineIcon from "@mui/icons-material/MailOutline";
// import LocationOnIcon from "@mui/icons-material/LocationOn";
// import Divider from "@mui/material/Divider";

// import MenuIcon from "../../assets/images/menu.png";
// import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
// import LocalHospitalOutlinedIcon from "@mui/icons-material/LocalHospitalOutlined";
// import Modal from "@mui/material/Modal";

// import Slide from "@mui/material/Slide";
// import CloseBtn from "../../assets/images/close.png";
// import { useMediaQuery, Drawer } from "@mui/material";
// import { NavLink, useLocation } from "react-router-dom";

// import ViktigCarousel from "../ViktigCarousel/ViktigCarousel";
// import { LocalHospitalOutlined } from "@mui/icons-material";

// const BlinkingCircle = styled.div`
//   min-width: 12px;
//   min-height: 12px;
//   border-radius: 50%;
//   background-color: ${(props) => (props.isOpen ? "green" : "red")};
//   animation: blink 1.5s infinite; /* Adjust the timing for faster/slower blink */

//   @media (max-width: 599px) {
//     min-width: 11px;
//     min-height: 11px;
//   }

//   @keyframes blink {
//     0% {
//       opacity: 0.5;
//     }
//     50% {
//       opacity: 1;
//     }
//     100% {
//       opacity: 0.5;
//     }
//   }
// `;

// const HoursDiv = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   gap: 0.75rem;
//   top: 1.5rem;
//   // top: 4.75rem;
//   text-align: center;
//   margin-left: 1rem;
//   background-color: ${(props) => (props.isOpen ? "#E5FFF0" : "#FFE5E5")};
//   padding: 0.5rem 0.3rem 0.5rem 0;
//   width: 160px;
//   height: 25px;
//   border-radius: 700px;
//   box-shadow: 0 1.2px 1.5px 0 rgba(0, 0, 0, 0.25);
//   cursor: pointer;
//   opacity: 0;
//   animation: ${({ isLoaded }) => (isLoaded ? opacity : "none")} 1s ease forwards;
//   animation-delay: 0.3s;

//   @media (max-width: 1099px) {
//     position: absolute;
//     top: 1.75rem;
//     display: flex;
//     // top: 5.75rem;
//   }

//   @media (max-width: 599px) {
//     display: flex;
//     top: 1.85rem;
//     // top: 4.5rem;
//     margin-left: 0.5rem;
//   }
// `;

// const HoursText = styled.span`
//   font-family: "NunitoSansRegular", sans-serif;
//   font-size: .9rem;

//   @media (max-width: 599px) {
//   font-size: .9rem;`;

// const ModalHoursDiv = styled.div`
//   position: absolute;
//   top: 15px;
//   right: -73px;
//   padding: 0.4rem 5rem;
//   font-size: 0.9rem;
//   font-weight: bold;
//   color: #464747;
//   transform: rotate(25deg);
//   overflow: hidden;
//   z-index: 10;
//   letter-spacing: 3px;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   @media (max-width: 899px) {
//     letter-spacing: 1px;
//     padding: 0.3rem 5rem;
//     font-size: 0.7rem;
//     top: 10px;
//     right: -75px;
//   }
// `;

// const Sidebar = styled.div`
//   position: fixed;
//   right: -3px;
//   top: 50%;

//   background-color: rgb(255, 211, 211);
//   color: white;
//   padding: 15px 15px 9px;

//   font-size: 16px;
//   font-weight: bold;
//   z-index: 1000;
//   border-top-left-radius: 1000px;
//   border-bottom-left-radius: 1000px;
//   box-shadow: 0 1.2px 1.5px 0 rgba(0, 0, 0, 0.2);
//   cursor: pointer;
//   transition: all 0.3s ease-in-out;

//   opacity: 0;
//   animation: ${({ isLoaded }) => (isLoaded ? opacity : "none")} 1s ease forwards;
//   animation-delay: 0.3s;

//   &:hover {
//     scale: 1.05;
//   }

//   @media (min-width: 1599px) {
//     scale: 1.2;
//     right: 0;
//   }

//   @media (min-width: 1999px) {
//     scale: 1.4;
//     right: 5px;
//   }
// `;

// const CallButton = styled.div`
//   font-family: "NunitoSansRegular", sans-serif;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   cursor: pointer;
//   border-radius: 10px;
//   // border: 1px solid #ddd;
//   height: 150px;
//   box-shadow: 0 1.2px 3px 0.1px rgba(0, 0, 0, 0.3);
//   overflow: hidden;
//   margin-bottom: 1rem;

//   @media (max-width: 599px) {
//     flex-direction: column;
//     height: 160px;
//   }
// `;

// const NumDiv = styled.div`
//   width: 30%;
//   background-color: #e5fff0;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100%;
//   font-size: 1.2rem;
//   font-weight: 600;

//   @media (max-width: 599px) {
//     width: 100%;
//     height: 70px;
//   }
// `;

// const TextDiv = styled.div`
//   width: 70%;
//   height: 100%;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-evenly;
//   align-items: center;

//   text-align: center;
//   @media (max-width: 599px) {
//     width: 100%;
//   }
// `;

// const modalStyle = {
//   fontFamily: "NunitoSansRegular",
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "70%",
//   maxWidth: 600,
//   borderRadius: 3,
//   height: "580px",
//   maxHeight: "90%",
//   display: "flex",
//   flexDirection: "column",
//   justifyContent: "space-evenly",
//   bgcolor: "background.paper",
//   boxShadow: 24,
//   padding: "2rem",
//   paddingBottom: "1rem",
// };

// const StyledCloseBtn = styled.img`
//   position: absolute;
//   top: 0.8rem;
//   right: 1rem;
//   width: 20px;
//   padding: 10px;
//   cursor: pointer;

//   @media (max-width: 399px) {
//     top: 0.5rem;
//     right: 0.3rem;
//   }
// `;

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

// const slideRight = keyframes`
//   from {
//     opacity: 0;
//     transform: translateX(-30px);
//   }
//   to {
//     opacity: 1;
//     transform: translateY(0);
//   }`;

// const slideDown = keyframes`
//   from {
//     opacity: 0;
//     transform: translateY(-30px);
//   }
//   to {
//     opacity: 1;
//     transform: translateY(0);
//   }`;

// const slideUp = keyframes`

//   from {
//     opacity: 0;
//     transform: translateY(10px);
//   }
//   to {
//     opacity: 1;
//     transform: translateY(0);
//   }`;

// const slideLeft = keyframes`
//   from {
//     opacity: 0;
//     transform: translateX(30px);
//   }
//   to {
//     opacity: 1;
//     transform: translateY(0);
//   }`;

// const opacity = keyframes`
//   from {
//     opacity: 0;
//   }
//   to {

//     opacity: 1;
//   }`;

// const StyledNav = styled.nav`
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   align-items: center;
//   padding: 2.5rem 0;

//   @media (max-width: 1099px) {
//     padding-top: 4rem;
//   }

//   @media (max-width: 599px) {
//     padding-top: 8rem;
//   }
// `;

// const ContactLink = styled.a`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   text-decoration: none;
//   color: black;
//   gap: 0.25rem;
// `;

// const ContactText = styled.span`
//   font-family: Arial, Helvetica, sans-serif;
//   font-size: 16px;

//   @media (max-width: 899px) {
//     font-size: 13px;
//   }

//   @media (max-width: 699px) {
//     display: none;
//   }
// `;

// const ContactBar = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 1rem;
//   opacity: 0.4;
//   display: none;

//   @media (max-width: 699px) {
//     position: absolute;
//     top: 1.5rem;
//     left: 1.5rem;
//   }
// `;

// const StyledCallIcon = styled(CallIcon)`
//   font-size: 14px;

//   @media (max-width: 620px) {
//     font-size: 20px;
//   }
// `;

// const StyledMailOutlineIcon = styled(MailOutlineIcon)`
//   font-size: 14px;

//   @media (max-width: 620px) {
//     font-size: 20px;
//   }
// `;

// const StyledLocationOnIcon = styled(LocationOnIcon)`
//   font-size: 14px;

//   @media (max-width: 620px) {
//     font-size: 18px;
//   }
// `;

// const LogoDiv = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   width: 100%;
//   margin: 3rem 0 1.75rem;
//   display: none;

//   @media (max-width: 699px) {
//     justify-content: center;
//     gap: 5rem;
//     margin-top: 4rem;
//     margin-bottom: 1rem;
//   }

//   @media (max-width: 499px) {
//     justify-content: space-between;
//     gap: 0.75rem;
//   }
// `;

// const StyledLink = styled(Link)`
//   text-decoration: none;
//   color: black;
//   font-size: 1.5rem;
//   display: flex;
//   justify-content: center;
// `;

// const StyledTab = styled(Tab)`
//   flex-grow: 1;
// `;

// const StyledButton = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 40px;
//   background-color: #0051a0;
//   color: white;
//   border-radius: 500px;
//   padding: 0 1.5rem;
//   margin-right: 0.5rem;
//   cursor: pointer;

//   @media (max-width: 1199px) {
//     height: 35px;
//     padding: 0 1.25rem;
//   }

//   @media (max-width: 799px) {
//     height: 30px;
//     padding: 0 1rem;
//     font-size: 0.75rem;
//     margin-right: 0;
//   }
// `;

// const StyledLogo = styled.img`
//   // height: auto;
//   width: 100px;
//   max-height: 45px;
//   object-fit: contain;
//   cursor: pointer;
// `;

// const StyledSolaAutoLogo = styled.img`
//   width: 20vw;
//   min-width: 200px;
//   max-width: 250px;

//   @media (max-width: 699px) {
//     min-width: 150px;
//     width: 180px;
//   }
// `;

// const StyledBox = styled(Box)`
//   @media (max-width: 1099px) {
//     display: none;
//   }
// `;

// const MenuIconWrapper = styled.div`
//   display: none; /* Hide by default */

//   @media (max-width: 1099px) {
//     // background-color: #f9f9f9;
//     background: rgb(254, 254, 254, 0.7);
//     display: block; /* Show on smaller screens */
//     position: absolute;
//     top: 1.75rem;
//     // top: 5.65rem;
//     right: 1.25rem;
//     cursor: pointer;
//     z-index: 999;
//     display: flex;
//     align-items: center;
//     gap: 0.5rem;
//     border-radius: 12px;
//     height: 45px;
//     width: 50px;
//     box-shadow: 0.2px 1.2px 1.5px 0 rgba(0, 0, 0, 0.3);
//     align-items: center;
//     justify-content: center;
//   }

//   @media (max-width: 599px) {
//     top: 5.9rem;
//     // top: 8.25rem;
//   }
// `;

// const NavLinks = styled.div`
//   display: flex;
//   width: 95%;
//   height: 55px;
//   justify-content: space-between;
//   align-items: center;

//   opacity: 0;
//   animation: ${({ isLoaded }) => (isLoaded ? opacity : "none")} 1s ease forwards;
//   animation-delay: 0.3s;

//   @media (min-width: 1599px) {
//     max-width: 1600px;
//   }

//   @media (max-width: 1099px) {
//     display: none;
//   }

//   a {
//     text-decoration: none;
//     font-size: 1.1rem;
//     transition: all 0.4s ease-in-out;
//     padding: 0.5rem 2rem;

//     // &.active {
//     //   font-size: 1.05rem;
//     //   background-color: #f9f9f9;
//     //   border-radius: 100px;
//     //   box-shadow: 0 1.2px 1.5px 0 rgba(0, 0, 0, 0.3);
//     //   font-weight: 900;
//     //   padding: 1rem 2rem;
//     // }

//     &.active {
//       font-size: 1.2rem;
//       font-weight: 900;
//     }

//     &:hover {
//       scale: 1.05;
//     }
//   }
// `;

// const ActiveLink = styled.div`
//   font-size: 1.2rem;
//   position: absolute;
//   top: 1.75rem;
//   // top: 5.65rem;
//   left: 1.5rem;
//   // background-color: #f9f9f9;
//   background: rgb(254, 254, 254, 0.7);
//   border-radius: 12px;
//   box-shadow: 0 1.2px 1.5px 0 rgba(0, 0, 0, 0.3);
//   padding: 0.5rem 1.8rem;

//   @media (min-width: 1099px) {
//     display: none;
//   }

//   @media (max-width: 599px) {
//     top: 5.9rem;
//     // top: 8.255rem;
//   }
// `;

// const NavigationLinks = styled.div`
//   display: flex;
//   width: 70%;
//   justify-content: space-evenly;
// `;

// const navLinks = [
//   { label: "Hjem", to: "/" },
//   { label: "Kontakt", to: "/kontakt" },
//   { label: "Nyttig", to: "/nyttig" },

//   { label: "Betaling", to: "/betaling" },
//   { label: "Ansatte", to: "/ansatte" },
// ];

// function Nav() {
//   const [value, setValue] = useState(0);
//   const [openDialog, setOpenDialog] = useState(false);
//   const [openModal2, setOpenModal2] = useState(false);

//   const location = useLocation();

//   const [isLoaded, setIsLoaded] = useState(false);

//   const [isOpen, setIsOpen] = useState(isOpenNow());

//   const [isMobile, setIsMobile] = useState(false);

//   const [userData, setUserData] = useState(null);

//   const [showHoursDiv, setShowHoursDiv] = useState(false);

//   const navigate = useNavigate();

//   useEffect(() => {
//     // const fetchUserData = localStorage.getItem("userData");
//     const fetchUserData = sessionStorage.getItem("userData");
//     const parsedUserData = JSON.parse(fetchUserData);
//     setUserData(JSON.parse(parsedUserData.acf.clinicdata));
//     // console.log(
//     //   "User data fetched and stored:",
//     //   JSON.parse(parsedUserData.acf.clinicdata)
//     // );
//     setIsLoaded(true);
//   }, []);

//   // function isOpenNow() {
//   //   // const fetchedUserData = localStorage.getItem("userData");
//   //   const fetchedUserData = sessionStorage.getItem("userData");
//   //   const parsedUserData = JSON.parse(fetchedUserData);
//   //   const functionUserData = JSON.parse(parsedUserData.acf.clinicdata);

//   //   // Business hours and holidays data
//   //   // const businessHours = {
//   //   //   Monday: ["08:00-11:00", "12:00-16:00"],
//   //   //   Tuesday: ["08:00-11:00", "12:00-16:00"],
//   //   //   Wednesday: ["08:00-11:00", "12:00-16:00"],
//   //   //   Thursday: ["08:00-11:00", "12:00-16:00"],
//   //   //   Friday: ["08:00-11:00", "12:00-16:00"],
//   //   //   Saturday: ["08:00-11:00"],
//   //   //   Sunday: [], // Closed
//   //   // };

//   //   const norwegianToEnglishDays = {
//   //     Mandag: "Monday",
//   //     Tirsdag: "Tuesday",
//   //     Onsdag: "Wednesday",
//   //     Torsdag: "Thursday",
//   //     Fredag: "Friday",
//   //     Lørdag: "Saturday",
//   //     Søndag: "Sunday",
//   //   };

//   //   const closedDates = functionUserData.closedDates;

//   //   // Function to convert Norwegian days to English and map to businessHours format
//   //   const businessHours = Object.keys(functionUserData.openPhoneHours).reduce(
//   //     (acc, day) => {
//   //       const hours = functionUserData.openPhoneHours[day];
//   //       const englishDay = norwegianToEnglishDays[day];

//   //       // Check if all from and to fields are empty, if yes mark as closed
//   //       if (!hours.from1 && !hours.from2 && !hours.to1 && !hours.to2) {
//   //         acc[englishDay] = []; // Closed
//   //       } else {
//   //         // If 'sameAsMandag' is true, copy Monday's hours
//   //         if (hours.sameAsMandag) {
//   //           const monday = functionUserData.openPhoneHours.Mandag;
//   //           acc[englishDay] = [
//   //             `${monday.from1}-${monday.to1}`,
//   //             `${monday.from2}-${monday.to2}`,
//   //           ];
//   //         } else {
//   //           // Handle half-day case or normal day
//   //           const timeRanges = [];
//   //           if (hours.from1 && hours.to1)
//   //             timeRanges.push(`${hours.from1}-${hours.to1}`);
//   //           if (hours.from2 && hours.to2)
//   //             timeRanges.push(`${hours.from2}-${hours.to2}`);

//   //           acc[englishDay] = timeRanges;
//   //         }
//   //       }

//   //       return acc;
//   //     },
//   //     {}
//   //   );

//   //   console.log(businessHours);

//   //   const today = new Date();
//   //   const currentDay = today.toLocaleString("en-us", { weekday: "long" });
//   //   const currentTime = today.getHours() * 60 + today.getMinutes(); // Current time in minutes

//   //   // Check if the business is closed today (holiday or other closed days)
//   //   if (closedDates.includes(today.toISOString().split("T")[0])) {
//   //     return { open: false, message: "Telefonen er stengt" };
//   //   }

//   //   const hours = businessHours[currentDay];

//   //   // Iterate over all open hours for today
//   //   for (let i = 0; i < hours.length; i++) {
//   //     const range = hours[i];
//   //     const [start, end] = range.split("-");
//   //     const [startHour, startMinute] = start.split(":").map(Number);
//   //     const [endHour, endMinute] = end.split(":").map(Number);

//   //     const startTime = startHour * 60 + startMinute;
//   //     const endTime = endHour * 60 + endMinute;

//   //     // If the current time is within an open period
//   //     if (currentTime >= startTime && currentTime <= endTime) {
//   //       return { open: true, message: "Telefonen er åpen" };
//   //     }
//   //   }

//   //   // If no open period is found for today
//   //   return { open: false, message: "Telefonen er stengt" };
//   // }

//   function isOpenNow() {
//     const fetchedUserData = sessionStorage.getItem("userData");
//     const parsedUserData = JSON.parse(fetchedUserData);
//     const functionUserData = JSON.parse(parsedUserData.acf.clinicdata);

//     const norwegianToEnglishDays = {
//       Mandag: "Monday",
//       Tirsdag: "Tuesday",
//       Onsdag: "Wednesday",
//       Torsdag: "Thursday",
//       Fredag: "Friday",
//       Lørdag: "Saturday",
//       Søndag: "Sunday",
//     };

//     const closedDates = functionUserData.closedDates;

//     // Function to convert Norwegian days to English and map to businessHours format
//     const businessHours = Object.keys(functionUserData.openPhoneHours).reduce(
//       (acc, day) => {
//         const hours = functionUserData.openPhoneHours[day];
//         const englishDay = norwegianToEnglishDays[day];

//         // If isClosed is true, mark the day as closed
//         if (
//           hours.isClosed ||
//           (!hours.from1 && !hours.from2 && !hours.to1 && !hours.to2)
//         ) {
//           acc[englishDay] = []; // Closed
//         } else {
//           // If 'sameAsMandag' is true, copy Monday's hours
//           if (hours.sameAsMandag) {
//             const monday = functionUserData.openPhoneHours.Mandag;
//             acc[englishDay] = [
//               `${monday.from1}-${monday.to1}`,
//               `${monday.from2}-${monday.to2}`,
//             ];
//           } else {
//             // Handle half-day case or normal day
//             const timeRanges = [];
//             if (hours.from1 && hours.to1)
//               timeRanges.push(`${hours.from1}-${hours.to1}`);
//             if (hours.from2 && hours.to2)
//               timeRanges.push(`${hours.from2}-${hours.to2}`);

//             acc[englishDay] = timeRanges;
//           }
//         }

//         return acc;
//       },
//       {}
//     );

//     // console.log(businessHours);

//     const today = new Date();
//     const currentDay = today.toLocaleString("en-us", { weekday: "long" });
//     const currentTime = today.getHours() * 60 + today.getMinutes(); // Current time in minutes

//     // Check if the business is closed today (holiday or other closed days)
//     if (closedDates.includes(today.toISOString().split("T")[0])) {
//       return { open: false, message: "TLF STENGT" };
//     }

//     const hours = businessHours[currentDay];

//     // Iterate over all open hours for today
//     for (let i = 0; i < hours.length; i++) {
//       const range = hours[i];
//       const [start, end] = range.split("-");
//       const [startHour, startMinute] = start.split(":").map(Number);
//       const [endHour, endMinute] = end.split(":").map(Number);

//       const startTime = startHour * 60 + startMinute;
//       const endTime = endHour * 60 + endMinute;

//       // If the current time is within an open period
//       if (currentTime >= startTime && currentTime <= endTime) {
//         return { open: true, message: "TLF ÅPEN" };
//       }
//     }

//     // If no open period is found for today
//     return { open: false, message: "TLF STENGT" };
//   }

//   useEffect(() => {
//     // Detect if user is on a mobile device
//     const handleResize = () => {
//       if (window.innerWidth <= 768) {
//         setIsMobile(true);
//       } else {
//         setIsMobile(false);
//       }

//       if (window.innerWidth <= 1099) {
//         setShowHoursDiv(true);
//       } else {
//         setShowHoursDiv(false);
//       }
//     };

//     handleResize(); // check initially
//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   const handleClick = (number) => {
//     if (isMobile) {
//       window.location.href = `tel:${number}`;
//     }
//   };

//   const handleHoursClick = () => {
//     if (isMobile && userData.contactInfo.phone && isOpen.open) {
//       window.location.href = `tel:${userData.contactInfo.phone}`;
//     } else {
//       setOpenModal2(true);
//     }
//   };

//   useEffect(() => {
//     const interval = setInterval(() => setIsOpen(isOpenNow()), 60000);
//     return () => clearInterval(interval);
//   }, []);

//   useEffect(() => {
//     setIsLoaded(true);
//   }, []);

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   const handleOpenDialog = () => {
//     setOpenDialog(true);
//   };

//   const handleCloseDialog = () => {
//     setOpenDialog(false);
//   };

//   const handleNavigation = (index) => {
//     setValue(index);
//     handleCloseDialog();
//   };

//   const handleBookAppointment = () => {
//     const kontaktOssIndex = navLinks.findIndex(
//       (link) => link.label === "Kontakt oss"
//     );
//     if (kontaktOssIndex !== -1) {
//       setValue(kontaktOssIndex);
//     }
//   };

//   const activeLink = navLinks.find((link) => link.to === location.pathname);

//   return (
//     <>
//       {isLoaded && userData && (
//         <>
//           {userData.selectedFeatures.nodnummerKnapp && (
//             <Sidebar
//               tabIndex="0"
//               role="button"
//               aria-label="Finn telefonnummer til helsevesenet"
//               isLoaded={isLoaded}
//               onClick={() => setOpenModal2(true)}
//               onKeyDown={(e) => {
//                 if (e.key === "Enter" || e.keyCode === 13) {
//                   e.preventDefault(); // Prevent form submission on Enter key
//                   setOpenModal2(true);
//                 }
//               }}
//             >
//               <LocalPhoneIcon />
//             </Sidebar>
//           )}

//           <Modal
//             open={openModal2}
//             onClose={() => setOpenModal2(false)}
//             aria-labelledby="modal-title"
//             aria-describedby="modal-description"
//           >
//             <Box sx={modalStyle}>
//               <StyledCloseBtn
//                 role="button"
//                 tabIndex="0"
//                 aria-label="Lukkeknapp"
//                 src={CloseBtn}
//                 alt="Close button"
//                 className="close-btn"
//                 onClick={() => setOpenModal2(false)}
//                 onKeyDown={(e) => {
//                   if (e.key === "Enter" || e.keyCode === 13) {
//                     e.preventDefault(); // Prevent form submission on Enter key
//                     setOpenModal2(false);
//                   }
//                 }}
//               />
//               <h2
//                 tabIndex="0"
//                 style={{ textAlign: "center", margin: "1rem 0 1.5rem" }}
//                 id="modal-title"
//               >
//                 Hvem vil du kontakte?
//               </h2>
//               <CallButton
//                 role="button"
//                 tabIndex="0"
//                 aria-label="Ring nødtelefon 113"
//                 onClick={() => handleClick(113)}
//                 onKeyDown={(e) => {
//                   if (e.key === "Enter" || e.keyCode === 13) {
//                     e.preventDefault(); // Prevent form submission on Enter key
//                     handleClick(113);
//                   }
//                 }}
//               >
//                 <NumDiv
//                   style={{
//                     // background: "#FFE5E5",
//                     background: "#FEF4F4",
//                   }}
//                 >
//                   113
//                 </NumDiv>
//                 <TextDiv>
//                   <h2 tabIndex="0" style={{ margin: 0, letterSpacing: 2.5 }}>
//                     Nødtelefon
//                   </h2>
//                   <p tabIndex="0" style={{ margin: "0 1rem" }}>
//                     Ved akutt eller livstruende situasjon.
//                   </p>
//                 </TextDiv>
//               </CallButton>

//               <CallButton
//                 role="button"
//                 tabIndex="0"
//                 aria-label="Ring legevakt 116117"
//                 onClick={() => handleClick(116117)}
//                 onKeyDown={(e) => {
//                   if (e.key === "Enter" || e.keyCode === 13) {
//                     e.preventDefault(); // Prevent form submission on Enter key
//                     handleClick(116117);
//                   }
//                 }}
//               >
//                 <NumDiv
//                   style={{
//                     // background: "#fffbe5",
//                     background: "#FEFAF4",
//                   }}
//                 >
//                   116 117
//                 </NumDiv>
//                 <TextDiv>
//                   <h2 tabIndex="0" style={{ margin: 0, letterSpacing: 2.5 }}>
//                     Legevakt
//                   </h2>
//                   <p tabIndex="0" style={{ margin: "0 1rem", maxWidth: "80%" }}>
//                     Ved behov for helsehjelp som ikke kan vente, når fastlegen
//                     ikke er tilgjengelig.
//                   </p>
//                 </TextDiv>
//               </CallButton>

//               {userData.contactInfo.phone && (
//                 <CallButton
//                   role="button"
//                   tabIndex="0"
//                   aria-label={`Ring legekontoret ${userData.contactInfo.phone}`}
//                   onClick={() => handleClick(userData.contactInfo.phone)}
//                   onKeyDown={(e) => {
//                     if (e.key === "Enter" || e.keyCode === 13) {
//                       e.preventDefault();
//                       handleClick(userData.contactInfo.phone);
//                     }
//                   }}
//                   style={{ position: "relative" }} // Ensure the button is a positioned ancestor
//                 >
//                   {/* HoursDiv Banner */}
//                   {(userData.openPhoneHours.Mandag.from1 ||
//                     userData.openPhoneHours.Tirsdag.from1 ||
//                     userData.openPhoneHours.Onsdag.from1 ||
//                     userData.openPhoneHours.Torsdag.from1 ||
//                     userData.openPhoneHours.Fredag.from1 ||
//                     userData.openPhoneHours.Lørdag.from1 ||
//                     userData.openPhoneHours.Søndag.from1) && (
//                     <ModalHoursDiv
//                       style={{
//                         background: isOpen.open
//                           ? "#E5FFF0"
//                           : "rgb(255, 211, 211)",
//                         paddingRight: isOpen.open ? "5.2rem" : "4.9rem",
//                       }}
//                       isLoaded={isLoaded}
//                       isOpen={isOpen.open}
//                       onClick={() => handleHoursClick()}
//                     >
//                       {isOpen.message}
//                     </ModalHoursDiv>
//                   )}

//                   {/* Phone Number Display */}
//                   <NumDiv
//                     style={{
//                       background: "#F3FDFA",
//                       textAlign: "center",
//                       flexDirection: "column",
//                       justifyContent: "center",
//                       gap: ".5rem",
//                     }}
//                   >
//                     {userData.contactInfo.phone}
//                   </NumDiv>

//                   <TextDiv>
//                     <h2 tabIndex="0" style={{ margin: 0, letterSpacing: 2.5 }}>
//                       Legekontor
//                     </h2>
//                     <p
//                       tabIndex="0"
//                       style={{ margin: "0 1rem", maxWidth: "80%" }}
//                     >
//                       For vanlige legetimer og øyeblikkelig hjelp i kontorets
//                       åpningstid.
//                     </p>
//                   </TextDiv>
//                 </CallButton>
//               )}

//               {/* {userData.contactInfo.phone && (
//                 <CallButton
//                   role="button"
//                   tabIndex="0"
//                   aria-label={`Ring legekontoret ${userData.contactInfo.phone}`}
//                   onClick={() => handleClick(userData.contactInfo.phone)}
//                   onKeyDown={(e) => {
//                     if (e.key === "Enter" || e.keyCode === 13) {
//                       e.preventDefault(); // Prevent form submission on Enter key
//                       handleClick(userData.contactInfo.phone);
//                     }
//                   }}
//                 >
//                   <NumDiv
//                     style={{
//                       background: "#F3FDFA",
//                       textAlign: "center",
//                       flexDirection: "column",
//                       justifyContent: "center",
//                       gap: ".5rem",
//                     }}
//                   >
//                     {userData.contactInfo.phone}
//                   </NumDiv>
//                   <TextDiv>
//                     <h2 tabIndex="0" style={{ margin: 0, letterSpacing: 2.5 }}>
//                       Legekontor
//                     </h2>
//                     <p
//                       tabIndex="0"
//                       style={{ margin: "0 1rem", maxWidth: "80%" }}
//                     >
//                       For vanlige legetimer og øyeblikkelig hjelp i kontorets
//                       åpningstid.
//                     </p>
//                   </TextDiv>
//                 </CallButton>
//               )} */}
//             </Box>
//           </Modal>

//           {/* <ViktigCarousel news={userData.importantInfo} /> */}

//           <StyledNav>
//             {activeLink && <ActiveLink>{activeLink.label}</ActiveLink>}
//             <MenuIconWrapper onClick={handleOpenDialog}>
//               <img
//                 style={{ width: 25, height: 25, opacity: 0.7 }}
//                 src={MenuIcon}
//                 alt="Menu icon"
//               />
//             </MenuIconWrapper>

//             {showHoursDiv && (
//               <>
//                 {(userData.openPhoneHours.Mandag.from1 ||
//                   userData.openPhoneHours.Tirsdag.from1 ||
//                   userData.openPhoneHours.Onsdag.from1 ||
//                   userData.openPhoneHours.Torsdag.from1 ||
//                   userData.openPhoneHours.Fredag.from1 ||
//                   userData.openPhoneHours.Lørdag.from1 ||
//                   userData.openPhoneHours.Søndag.from1) && (
//                   <HoursDiv
//                     isLoaded={isLoaded}
//                     isOpen={isOpen.open}
//                     onClick={() => handleHoursClick()}
//                   >
//                     {/* <BlinkingCircle isOpen={isOpen.open} /> */}
//                     <LocalPhoneIcon
//                       style={{
//                         fontSize: "1.4rem",
//                         marginBottom: "-0.1rem",
//                       }}
//                     />
//                     <HoursText>{isOpen.message}</HoursText>
//                   </HoursDiv>
//                 )}
//               </>
//             )}

//             <NavLinks isLoaded={isLoaded}>
//               {userData.doctorsOffice.logoImage ? (
//                 <StyledLogo
//                   tabIndex="0"
//                   role="button"
//                   aria-label="Hjemknapp"
//                   src={userData.doctorsOffice.logoImage}
//                   alt="Logo"
//                   onClick={() => navigate("/")}
//                   onKeyDown={(e) => {
//                     if (e.key === "Enter" || e.keyCode === 13) {
//                       e.preventDefault(); // Prevent form submission on Enter key
//                       navigate("/");
//                     }
//                   }}
//                 />
//               ) : (
//                 <StyledLogo usually tabindex="-1" />
//               )}
//               <NavigationLinks>
//                 {navLinks.map((link, index) => (
//                   <NavLink
//                     key={index}
//                     to={link.to}
//                     className={({ isActive }) => (isActive ? "active" : "")}
//                   >
//                     {link.label}
//                   </NavLink>
//                 ))}
//               </NavigationLinks>

//               {(userData.openPhoneHours.Mandag.from1 ||
//                 userData.openPhoneHours.Tirsdag.from1 ||
//                 userData.openPhoneHours.Onsdag.from1 ||
//                 userData.openPhoneHours.Torsdag.from1 ||
//                 userData.openPhoneHours.Fredag.from1 ||
//                 userData.openPhoneHours.Lørdag.from1 ||
//                 userData.openPhoneHours.Søndag.from1) && (
//                 <HoursDiv
//                   tabIndex="0"
//                   role="button"
//                   aria-label="Telefonknapp"
//                   isLoaded={isLoaded}
//                   isOpen={isOpen.open}
//                   onClick={() => handleHoursClick()}
//                   onKeyDown={(e) => {
//                     if (e.key === "Enter" || e.keyCode === 13) {
//                       e.preventDefault(); // Prevent form submission on Enter key
//                       handleHoursClick();
//                     }
//                   }}
//                 >
//                   {/* <BlinkingCircle isOpen={isOpen.open} /> */}
//                   <LocalPhoneIcon
//                     style={{
//                       fontSize: "1.4rem",
//                       marginBottom: "-0.1rem",
//                     }}
//                   />
//                   <HoursText>{isOpen.message}</HoursText>
//                 </HoursDiv>
//               )}
//             </NavLinks>
//             <NavigationDrawer
//               open={openDialog}
//               onClose={handleCloseDialog}
//               navLinks={navLinks}
//               onNavigate={handleNavigation}
//             />
//           </StyledNav>
//         </>
//       )}
//     </>
//   );
// }

// function NavigationDrawer({ open, onClose, navLinks, onNavigate }) {
//   const navigate = useNavigate();

//   const handleNavigate = (index) => {
//     onNavigate(index);
//     navigate(navLinks[index].to);
//   };

//   return (
//     <Drawer
//       anchor="bottom"
//       open={open}
//       onClose={onClose}
//       PaperProps={{
//         sx: { backgroundColor: "transparent", boxShadow: "none" },
//       }}
//       onClick={onClose}
//     >
//       <Box
//         sx={{
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//           gap: "1rem",
//           backgroundColor: "transparent",
//           padding: "1.5rem",
//           paddingLeft: "5vw",
//           paddingRight: "5vw",
//         }}
//       >
//         {navLinks.map((link, index) => (
//           <div
//             key={index}
//             onClick={() => handleNavigate(index)}
//             style={{
//               borderRadius: "500px",
//               textAlign: "center",
//               backgroundColor: "white",
//               boxShadow: "0 1.2px 1.5px 0 rgba(0,0,0,0.3)",
//               width: "85%",
//               maxWidth: "500px",
//               cursor: "pointer",
//               opacity: "0.95",
//             }}
//           >
//             <h2
//               style={{
//                 color: "#3A3A3A",
//                 fontSize: "1rem",
//                 fontWeight: "bold",
//                 fontFamily: "Arial",
//               }}
//             >
//               {link.label}
//             </h2>
//           </div>
//         ))}
//       </Box>
//     </Drawer>
//   );
// }

// function CustomTabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <></>
//     // <div
//     //   role="tabpanel"
//     //   hidden={value !== index}
//     //   id={`simple-tabpanel-${index}`}
//     //   aria-labelledby={`simple-tab-${index}`}
//     //   {...other}
//     // >
//     //   {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
//     // </div>
//   );
// }

// CustomTabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

// function BasicTabs({ navLinks, value, handleChange }) {
//   const isLargeScreen = useMediaQuery("(min-width:1099px)");

//   return (
//     <>
//       <StyledBox sx={{ width: "100%" }}>
//         <Tabs
//           value={value}
//           onChange={handleChange}
//           aria-label="basic tabs example"
//           TabIndicatorProps={{
//             style: {
//               backgroundColor: "#31303500", // Underline color
//             },
//           }}
//         >
//           {isLargeScreen &&
//             navLinks.map((link, index) => (
//               <StyledTab
//                 key={index}
//                 label={link.label}
//                 component={StyledLink}
//                 to={link.to}
//                 sx={{
//                   fontFamily: "NunitoSansRegular",
//                   fontSize: "1.05rem", // Font size for all tabs
//                   color: "#313035", // Color for the active tab text

//                   letterSpacing: "2px", // Letter spacing for all tabs
//                   "&.Mui-selected": {
//                     backgroundColor: "#f9f9f9",

//                     borderRadius: "10px",
//                     boxShadow: "0 1.2px 1.5px 0 rgba(0,0,0,0.3)",
//                     margin: "0.5rem",
//                     padding: "1.25rem 0",
//                     color: "#313035", // Color for the active tab text
//                     fontFamily: "NunitoSansMedium",
//                     fontWeight: 900, // Bold text for the active tab
//                   },
//                 }}
//               />
//             ))}
//         </Tabs>
//       </StyledBox>

//       {!isLargeScreen &&
//         navLinks.map((link, index) => (
//           <CustomTabPanel key={index} value={value} index={index}>
//             <StyledLink to={link.to}>{link.label}</StyledLink>
//           </CustomTabPanel>
//         ))}
//     </>
//   );
// }

// BasicTabs.propTypes = {
//   navLinks: PropTypes.arrayOf(
//     PropTypes.shape({
//       label: PropTypes.string.isRequired,
//       to: PropTypes.string.isRequired,
//     })
//   ).isRequired,
//   value: PropTypes.number.isRequired,
//   handleChange: PropTypes.func.isRequired,
// };

// export default Nav;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import styled, { keyframes } from "styled-components";

import { Link, useNavigate } from "react-router-dom";

import MenuIcon from "../../assets/images/menu.png";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Modal from "@mui/material/Modal";

import CloseBtn from "../../assets/images/close.png";
import { useMediaQuery, Drawer } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";

const HoursDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  top: 1.5rem;
  text-align: center;
  background-color: ${(props) => (props.isOpen ? "#E5FFF0" : "#FFE5E5")};
  padding: 0.5rem 0.3rem 0.5rem 0;
  width: 160px;
  height: 25px;
  border-radius: 700px;
  box-shadow: 0 1.2px 1.5px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
  opacity: 0;
  animation: ${({ isLoaded }) => (isLoaded ? opacity : "none")} 1s ease forwards;
  animation-delay: 0.3s;

  @media (max-width: 1099px) {
    position: absolute;
    top: 1.75rem;
    display: flex;
  }

  @media (max-width: 599px) {
    display: flex;
    top: 1.85rem;
    margin-left: 0.5rem;
  }
`;

const HoursText = styled.span`
  font-family: "NunitoSansRegular", sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
`;

const ModalHoursDiv = styled.div`
  position: absolute;
  top: 15px;
  right: -73px;
  padding: 0.4rem 5rem;
  font-size: 0.9rem;
  font-weight: bold;
  color: #464747;
  transform: rotate(25deg);
  overflow: hidden;
  z-index: 10;
  letter-spacing: 3px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 899px) {
    letter-spacing: 1px;
    padding: 0.3rem 5rem;
    font-size: 0.7rem;
    top: 10px;
    right: -75px;
  }
`;

const Sidebar = styled.div`
  position: fixed;
  right: -3px;
  top: 50%;

  background-color: rgb(255, 211, 211);
  color: white;
  padding: 15px 15px 9px;

  font-size: 16px;
  font-weight: bold;
  z-index: 1000;
  border-top-left-radius: 1000px;
  border-bottom-left-radius: 1000px;
  box-shadow: 0 1.2px 1.5px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  opacity: 0;
  animation: ${({ isLoaded }) => (isLoaded ? opacity : "none")} 1s ease forwards;
  animation-delay: 0.3s;

  &:hover {
    scale: 1.05;
  }

  @media (min-width: 1599px) {
    scale: 1.2;
    right: 0;
  }

  @media (min-width: 1999px) {
    scale: 1.4;
    right: 5px;
  }
`;

const CallButton = styled.div`
  font-family: "NunitoSansRegular", sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  // border: 1px solid #ddd;
  height: 150px;
  box-shadow: 0 1.2px 3px 0.1px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  margin-bottom: 1rem;

  @media (max-width: 599px) {
    flex-direction: column;
    height: 160px;
  }
`;

const NumDiv = styled.div`
  width: 30%;
  background-color: #e5fff0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.2rem;
  font-weight: 600;

  @media (max-width: 599px) {
    width: 100%;
    height: 70px;
  }
`;

const TextDiv = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  text-align: center;
  @media (max-width: 599px) {
    width: 100%;
  }
`;

const modalStyle = {
  fontFamily: "NunitoSansRegular",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  maxWidth: 600,
  borderRadius: 3,
  height: "580px",
  maxHeight: "90%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "2rem",
  paddingBottom: "1rem",
};

const StyledCloseBtn = styled.img`
  position: absolute;
  top: 0.8rem;
  right: 1rem;
  width: 20px;
  padding: 10px;
  cursor: pointer;

  @media (max-width: 399px) {
    top: 0.5rem;
    right: 0.3rem;
  }
`;

const opacity = keyframes`
  from {
    opacity: 0;
  }
  to {

    opacity: 1; 
  }`;

const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 2.5rem 0;

  @media (max-width: 1099px) {
    padding-top: 4rem;
  }

  @media (max-width: 599px) {
    padding-top: 8rem;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
`;

const StyledTab = styled(Tab)`
  flex-grow: 1;
`;

const StyledLogo = styled.img`
  width: 160px;
  max-height: 45px;
  object-fit: contain;
  // object-position: left;
  cursor: pointer;
`;

const StyledBox = styled(Box)`
  @media (max-width: 1099px) {
    display: none;
  }
`;

const MenuIconWrapper = styled.div`
  display: none; /* Hide by default */

  @media (max-width: 1099px) {
    background: rgb(254, 254, 254, 0.7);
    display: block; /* Show on smaller screens */
    position: absolute;
    top: 1.75rem;
    right: 1.25rem;
    cursor: pointer;
    z-index: 999;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border-radius: 12px;
    height: 45px;
    width: 50px;
    box-shadow: 0.2px 1.2px 1.5px 0 rgba(0, 0, 0, 0.3);
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 599px) {
    top: 5.9rem;
  }
`;

const NavLinks = styled.div`
  display: flex;
  width: 95%;
  height: 55px;
  justify-content: space-between;
  align-items: center;

  opacity: 0;
  animation: ${({ isLoaded }) => (isLoaded ? opacity : "none")} 1s ease forwards;
  animation-delay: 0.3s;

  @media (min-width: 1599px) {
    max-width: 1600px;
  }

  @media (max-width: 1099px) {
    display: none;
  }

  a {
    text-decoration: none;
    font-size: 1.1rem;
    transition: all 0.4s ease-in-out;
    padding: 0.5rem 2rem;

    &.active {
      font-size: 1.2rem;
      font-weight: 900;
    }

    &:hover {
      scale: 1.05;
    }
  }
`;

const ActiveLink = styled.div`
  font-size: 1.2rem;
  position: absolute;
  top: 1.75rem;
  // top: 5.65rem;
  left: 1.5rem;
  // background-color: #f9f9f9;
  background: rgb(254, 254, 254, 0.7);
  border-radius: 12px;
  box-shadow: 0 1.2px 1.5px 0 rgba(0, 0, 0, 0.3);
  padding: 0.5rem 1.8rem;

  @media (min-width: 1099px) {
    display: none;
  }

  @media (max-width: 599px) {
    top: 5.9rem;
    // top: 8.255rem;
  }
`;

const NavigationLinks = styled.div`
  display: flex;
  width: 70%;
  justify-content: space-evenly;
`;

const navLinks = [
  { label: "Hjem", to: "/" },
  { label: "Kontakt", to: "/kontakt" },
  { label: "Nyttig", to: "/nyttig" },

  { label: "Betaling", to: "/betaling" },
  { label: "Ansatte", to: "/ansatte" },
];

const Nav = ({ userData }) => {
  const [value, setValue] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);

  const location = useLocation();

  const [isLoaded, setIsLoaded] = useState(false);

  const [isOpen, setIsOpen] = useState(isOpenNow());

  const [isMobile, setIsMobile] = useState(false);

  const [showHoursDiv, setShowHoursDiv] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  function isOpenNow() {
    const norwegianToEnglishDays = {
      Mandag: "Monday",
      Tirsdag: "Tuesday",
      Onsdag: "Wednesday",
      Torsdag: "Thursday",
      Fredag: "Friday",
      Lørdag: "Saturday",
      Søndag: "Sunday",
    };

    const closedDates = userData.closedDates;

    // Function to convert Norwegian days to English and map to businessHours format
    const businessHours = Object.keys(userData.openPhoneHours).reduce(
      (acc, day) => {
        const hours = userData.openPhoneHours[day];
        const englishDay = norwegianToEnglishDays[day];

        // If isClosed is true, mark the day as closed
        if (
          hours.isClosed ||
          (!hours.from1 && !hours.from2 && !hours.to1 && !hours.to2)
        ) {
          acc[englishDay] = []; // Closed
        } else {
          // If 'sameAsMandag' is true, copy Monday's hours
          if (hours.sameAsMandag) {
            const monday = userData.openPhoneHours.Mandag;
            acc[englishDay] = [
              `${monday.from1}-${monday.to1}`,
              `${monday.from2}-${monday.to2}`,
            ];
          } else {
            // Handle half-day case or normal day
            const timeRanges = [];
            if (hours.from1 && hours.to1)
              timeRanges.push(`${hours.from1}-${hours.to1}`);
            if (hours.from2 && hours.to2)
              timeRanges.push(`${hours.from2}-${hours.to2}`);

            acc[englishDay] = timeRanges;
          }
        }

        return acc;
      },
      {}
    );

    const today = new Date();
    const currentDay = today.toLocaleString("en-us", { weekday: "long" });
    const currentTime = today.getHours() * 60 + today.getMinutes(); // Current time in minutes

    // Check if the business is closed today (holiday or other closed days)
    if (closedDates.includes(today.toISOString().split("T")[0])) {
      return { open: false, message: "TLF STENGT" };
    }

    const hours = businessHours[currentDay];

    // Iterate over all open hours for today
    for (let i = 0; i < hours.length; i++) {
      const range = hours[i];
      const [start, end] = range.split("-");
      const [startHour, startMinute] = start.split(":").map(Number);
      const [endHour, endMinute] = end.split(":").map(Number);

      const startTime = startHour * 60 + startMinute;
      const endTime = endHour * 60 + endMinute;

      // If the current time is within an open period
      if (currentTime >= startTime && currentTime <= endTime) {
        return { open: true, message: "TLF ÅPEN" };
      }
    }

    // If no open period is found for today
    return { open: false, message: "TLF STENGT" };
  }

  useEffect(() => {
    // Detect if user is on a mobile device
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }

      if (window.innerWidth <= 1099) {
        setShowHoursDiv(true);
      } else {
        setShowHoursDiv(false);
      }
    };

    handleResize(); // check initially
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClick = (number) => {
    if (isMobile) {
      window.location.href = `tel:${number}`;
    }
  };

  const handleHoursClick = () => {
    if (isMobile && userData.contactInfo.phone && isOpen.open) {
      window.location.href = `tel:${userData.contactInfo.phone}`;
    } else {
      setOpenModal2(true);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => setIsOpen(isOpenNow()), 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleNavigation = (index) => {
    setValue(index);
    handleCloseDialog();
  };

  const activeLink = navLinks.find((link) => link.to === location.pathname);

  return (
    <>
      {userData && (
        <>
          {userData.selectedFeatures.nodnummerKnapp && (
            <Sidebar
              tabIndex="0"
              role="button"
              aria-label="Finn telefonnummer til helsevesenet"
              isLoaded={isLoaded}
              onClick={() => setOpenModal2(true)}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.keyCode === 13) {
                  e.preventDefault(); // Prevent form submission on Enter key
                  setOpenModal2(true);
                }
              }}
            >
              <LocalPhoneIcon />
            </Sidebar>
          )}

          <Modal
            open={openModal2}
            onClose={() => setOpenModal2(false)}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box sx={modalStyle}>
              <StyledCloseBtn
                role="button"
                tabIndex="0"
                aria-label="Lukkeknapp"
                src={CloseBtn}
                alt="Close button"
                className="close-btn"
                onClick={() => setOpenModal2(false)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.keyCode === 13) {
                    e.preventDefault(); // Prevent form submission on Enter key
                    setOpenModal2(false);
                  }
                }}
              />
              <h2
                tabIndex="0"
                style={{ textAlign: "center", margin: "1rem 0 1.5rem" }}
                id="modal-title"
              >
                Hvem vil du kontakte?
              </h2>
              <CallButton
                role="button"
                tabIndex="0"
                aria-label="Ring nødtelefon 113"
                onClick={() => handleClick(113)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.keyCode === 13) {
                    e.preventDefault(); // Prevent form submission on Enter key
                    handleClick(113);
                  }
                }}
              >
                <NumDiv
                  style={{
                    // background: "#FFE5E5",
                    background: "#FEF4F4",
                  }}
                >
                  113
                </NumDiv>
                <TextDiv>
                  <h2 tabIndex="0" style={{ margin: 0, letterSpacing: 2.5 }}>
                    Nødtelefon
                  </h2>
                  <p tabIndex="0" style={{ margin: "0 1rem" }}>
                    Ved akutt eller livstruende situasjon.
                  </p>
                </TextDiv>
              </CallButton>

              <CallButton
                role="button"
                tabIndex="0"
                aria-label="Ring legevakt 116117"
                onClick={() => handleClick(116117)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.keyCode === 13) {
                    e.preventDefault(); // Prevent form submission on Enter key
                    handleClick(116117);
                  }
                }}
              >
                <NumDiv
                  style={{
                    // background: "#fffbe5",
                    background: "#FEFAF4",
                  }}
                >
                  116 117
                </NumDiv>
                <TextDiv>
                  <h2 tabIndex="0" style={{ margin: 0, letterSpacing: 2.5 }}>
                    Legevakt
                  </h2>
                  <p tabIndex="0" style={{ margin: "0 1rem", maxWidth: "80%" }}>
                    Ved behov for helsehjelp som ikke kan vente, når fastlegen
                    ikke er tilgjengelig.
                  </p>
                </TextDiv>
              </CallButton>

              {userData.contactInfo.phone && (
                <CallButton
                  role="button"
                  tabIndex="0"
                  aria-label={`Ring legekontoret ${userData.contactInfo.phone}`}
                  onClick={() => handleClick(userData.contactInfo.phone)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.keyCode === 13) {
                      e.preventDefault();
                      handleClick(userData.contactInfo.phone);
                    }
                  }}
                  style={{ position: "relative" }} // Ensure the button is a positioned ancestor
                >
                  {/* HoursDiv Banner */}
                  {(userData.openPhoneHours.Mandag.from1 ||
                    userData.openPhoneHours.Tirsdag.from1 ||
                    userData.openPhoneHours.Onsdag.from1 ||
                    userData.openPhoneHours.Torsdag.from1 ||
                    userData.openPhoneHours.Fredag.from1 ||
                    userData.openPhoneHours.Lørdag.from1 ||
                    userData.openPhoneHours.Søndag.from1) && (
                    <ModalHoursDiv
                      style={{
                        background: isOpen.open
                          ? "#E5FFF0"
                          : "rgb(255, 211, 211)",
                        paddingRight: isOpen.open ? "5.2rem" : "4.9rem",
                      }}
                      isLoaded={isLoaded}
                      isOpen={isOpen.open}
                      onClick={() => handleHoursClick()}
                    >
                      {isOpen.message}
                    </ModalHoursDiv>
                  )}

                  {/* Phone Number Display */}
                  <NumDiv
                    style={{
                      background: "#F3FDFA",
                      textAlign: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                      gap: ".5rem",
                    }}
                  >
                    {userData.contactInfo.phone}
                  </NumDiv>

                  <TextDiv>
                    <h2 tabIndex="0" style={{ margin: 0, letterSpacing: 2.5 }}>
                      Legekontor
                    </h2>
                    <p
                      tabIndex="0"
                      style={{ margin: "0 1rem", maxWidth: "80%" }}
                    >
                      For vanlige legetimer og øyeblikkelig hjelp i kontorets
                      åpningstid.
                    </p>
                  </TextDiv>
                </CallButton>
              )}
            </Box>
          </Modal>

          <StyledNav>
            {activeLink && <ActiveLink>{activeLink.label}</ActiveLink>}
            <MenuIconWrapper onClick={handleOpenDialog}>
              <img
                style={{ width: 25, height: 25, opacity: 0.7 }}
                src={MenuIcon}
                alt="Menu icon"
              />
            </MenuIconWrapper>

            {showHoursDiv && (
              <>
                {(userData.openPhoneHours.Mandag.from1 ||
                  userData.openPhoneHours.Tirsdag.from1 ||
                  userData.openPhoneHours.Onsdag.from1 ||
                  userData.openPhoneHours.Torsdag.from1 ||
                  userData.openPhoneHours.Fredag.from1 ||
                  userData.openPhoneHours.Lørdag.from1 ||
                  userData.openPhoneHours.Søndag.from1) && (
                  <HoursDiv
                    isLoaded={isLoaded}
                    isOpen={isOpen.open}
                    onClick={() => handleHoursClick()}
                  >
                    {/* <BlinkingCircle isOpen={isOpen.open} /> */}
                    <LocalPhoneIcon
                      style={{
                        fontSize: "1.4rem",
                        marginBottom: "-0.1rem",
                      }}
                    />
                    <HoursText>{isOpen.message}</HoursText>
                  </HoursDiv>
                )}
              </>
            )}

            <NavLinks isLoaded={isLoaded}>
              {userData.doctorsOffice.logoImage ? (
                <StyledLogo
                  tabIndex="0"
                  role="button"
                  aria-label="Hjemknapp"
                  src={userData.doctorsOffice.logoImage}
                  alt="Logo"
                  onClick={() => navigate("/")}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.keyCode === 13) {
                      e.preventDefault(); // Prevent form submission on Enter key
                      navigate("/");
                    }
                  }}
                />
              ) : (
                <StyledLogo usually tabindex="-1" />
              )}
              <NavigationLinks>
                {navLinks.map((link, index) => (
                  <NavLink
                    key={index}
                    to={link.to}
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    {link.label}
                  </NavLink>
                ))}
              </NavigationLinks>

              {(userData.openPhoneHours.Mandag.from1 ||
                userData.openPhoneHours.Tirsdag.from1 ||
                userData.openPhoneHours.Onsdag.from1 ||
                userData.openPhoneHours.Torsdag.from1 ||
                userData.openPhoneHours.Fredag.from1 ||
                userData.openPhoneHours.Lørdag.from1 ||
                userData.openPhoneHours.Søndag.from1) && (
                <HoursDiv
                  tabIndex="0"
                  role="button"
                  aria-label="Telefonknapp"
                  isLoaded={isLoaded}
                  isOpen={isOpen.open}
                  onClick={() => handleHoursClick()}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.keyCode === 13) {
                      e.preventDefault(); // Prevent form submission on Enter key
                      handleHoursClick();
                    }
                  }}
                >
                  <LocalPhoneIcon
                    style={{
                      fontSize: "1.4rem",
                      marginBottom: "-0.1rem",
                    }}
                  />
                  <HoursText>{isOpen.message}</HoursText>
                </HoursDiv>
              )}
            </NavLinks>
            <NavigationDrawer
              open={openDialog}
              onClose={handleCloseDialog}
              navLinks={navLinks}
              onNavigate={handleNavigation}
            />
          </StyledNav>
        </>
      )}
    </>
  );
};

function NavigationDrawer({ open, onClose, navLinks, onNavigate }) {
  const navigate = useNavigate();

  const handleNavigate = (index) => {
    onNavigate(index);
    navigate(navLinks[index].to);
  };

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { backgroundColor: "transparent", boxShadow: "none" },
      }}
      onClick={onClose}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          backgroundColor: "transparent",
          padding: "1.5rem",
          paddingLeft: "5vw",
          paddingRight: "5vw",
        }}
      >
        {navLinks.map((link, index) => (
          <div
            key={index}
            onClick={() => handleNavigate(index)}
            style={{
              borderRadius: "500px",
              textAlign: "center",
              backgroundColor: "white",
              boxShadow: "0 1.2px 1.5px 0 rgba(0,0,0,0.3)",
              width: "85%",
              maxWidth: "500px",
              cursor: "pointer",
              opacity: "0.95",
            }}
          >
            <h2
              style={{
                color: "#3A3A3A",
                fontSize: "1rem",
                fontWeight: "bold",
                fontFamily: "Arial",
              }}
            >
              {link.label}
            </h2>
          </div>
        ))}
      </Box>
    </Drawer>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return <></>;
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function BasicTabs({ navLinks, value, handleChange }) {
  const isLargeScreen = useMediaQuery("(min-width:1099px)");

  return (
    <>
      <StyledBox sx={{ width: "100%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{
            style: {
              backgroundColor: "#31303500", // Underline color
            },
          }}
        >
          {isLargeScreen &&
            navLinks.map((link, index) => (
              <StyledTab
                key={index}
                label={link.label}
                component={StyledLink}
                to={link.to}
                sx={{
                  fontFamily: "NunitoSansRegular",
                  fontSize: "1.05rem", // Font size for all tabs
                  color: "#313035", // Color for the active tab text

                  letterSpacing: "2px", // Letter spacing for all tabs
                  "&.Mui-selected": {
                    backgroundColor: "#f9f9f9",

                    borderRadius: "10px",
                    boxShadow: "0 1.2px 1.5px 0 rgba(0,0,0,0.3)",
                    margin: "0.5rem",
                    padding: "1.25rem 0",
                    color: "#313035", // Color for the active tab text
                    fontFamily: "NunitoSansMedium",
                    fontWeight: 900, // Bold text for the active tab
                  },
                }}
              />
            ))}
        </Tabs>
      </StyledBox>

      {!isLargeScreen &&
        navLinks.map((link, index) => (
          <CustomTabPanel key={index} value={value} index={index}>
            <StyledLink to={link.to}>{link.label}</StyledLink>
          </CustomTabPanel>
        ))}
    </>
  );
}

BasicTabs.propTypes = {
  navLinks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    })
  ).isRequired,
  value: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default Nav;
